// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TFYWq\\+G{position:absolute;z-index:1;top:50%;left:0;display:block;overflow:hidden;align-items:center;width:100%;height:24px;font-size:16px;font-weight:normal;line-height:24px;user-select:none;white-space:nowrap;text-overflow:ellipsis;pointer-events:none;color:var(--color-pink-sber);transition:all .25s linear;transform:translateY(-50%);padding-inline:16px;touch-action:none;will-change:transform}._7r6QtrX{height:48px}.eglm9HD{height:56px}.L86B7mu{top:12px;height:16px;font-size:12px;line-height:16px}.UKvGmSq{color:var(--color-graphite-sber-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputError": "TFYWq+G",
	"InputErrorSizeM": "_7r6QtrX",
	"InputErrorSizeL": "eglm9HD",
	"InputErrorIsFocused": "L86B7mu",
	"InputErrorIsDisabled": "UKvGmSq"
};
export default ___CSS_LOADER_EXPORT___;
