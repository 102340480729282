import React, {
  Ref,
  FC,
  useState,
  useEffect,
  ReactNode,
  useCallback,
  CSSProperties,
  RefObject
} from 'react';
import cn from 'classnames';
import defaultImage from './Image.assets/no-photo.png';
import styles from './Image.module.scss';

export interface IImageProps {
  /**
   * Ccылка на изображение
   */
  src?: string;

  /**
   * Набор возможных изображений для отображения в браузере
   */
  srcSet?: string;

  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Задаёт размеры изображения для разных макетов страницы
   */
  sizes?: string;

  /**
   * Описание изображения
   */
  alt?: string;

  /**
   * Ссылка на элемент
   */
  innerRef?: Ref<HTMLDivElement>;

  /**
   * Ссылка на DOM-элемент нативного контрола
   */
  controlRef?: RefObject<HTMLImageElement>;

  /**
   * Ширина изображения
   */
  width?: string | number;

  /**
   * Высота изображения
   */
  height?: string | number;

  /**
   * Ссылка на изображение при неудачной загрузке
   */
  fallbackSrc?: string;

  /**
   * Тип загрузки изображений
   */
  loading?: 'lazy' | 'eager';

  /**
   * Заглушка при загрузке
   */
  stub?: ReactNode;

  /**
   * Дополнительные стили
   */
  style?: CSSProperties;
}

export const Image: FC<IImageProps> = ({
  className,
  alt = 'изображение товара',
  src = defaultImage,
  srcSet,
  loading = 'lazy',
  fallbackSrc,
  stub,
  innerRef,
  controlRef,
  width,
  height,
  sizes,
  style = {},
  ...props
}) => {
  const [animation, setAnimation] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isFailed, setIsFailed] = useState(false);
  const [shouldRemoveStub, setShouldRemoveStub] =
    useState(false);

  const isStub = Boolean(stub);

  const imgClasses = cn(
    styles.Root,
    {
      [styles.Animated]: animation && isStub,
      [styles.Loaded]: isLoaded
    },
    className
  );

  const handleError = useCallback(() => {
    setIsFailed(true);
  }, []);

  const handleLoad = useCallback(() => {
    setIsLoaded(true);
  }, []);

  const handleRemoveStub = useCallback(() => {
    setShouldRemoveStub(true);
  }, []);

  const source = isFailed
    ? fallbackSrc || defaultImage
    : src;

  const image = (
    <img
      className={imgClasses}
      alt={alt}
      src={source}
      srcSet={srcSet}
      sizes={sizes}
      style={{ width, height, ...style }}
      loading={loading}
      ref={controlRef}
      onError={handleError}
      onLoad={handleLoad}
      onAnimationEnd={handleRemoveStub}
    />
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      if (isLoaded) {
        setAnimation(true);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [isLoaded, setAnimation]);

  if (stub) {
    return (
      <div
        className={styles.Control}
        ref={innerRef}
        {...props}
      >
        {isLoaded && !shouldRemoveStub && stub}
        {image}
      </div>
    );
  }

  return image;
};

if (process.env.NODE_ENV !== 'production') {
  Image.displayName = 'Image';
}
