// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".gH3BzmQ{display:grid;grid-template-areas:\"img\" \"text\" \"btn\";grid-template-columns:152px 1fr;grid-template-rows:auto auto;grid-gap:12px 0}.vdvWdrP{grid-area:img}.le9jk6U{display:flex;flex-flow:column nowrap;grid-area:text;gap:12px}.Saq71fK{align-self:end;grid-area:btn;margin-top:12px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "gH3BzmQ",
	"Img": "vdvWdrP",
	"Wrapper": "le9jk6U",
	"Btn": "Saq71fK"
};
export default ___CSS_LOADER_EXPORT___;
