import {
  viewBreakPoints,
  viewBreakPointsKeys
} from '../const';
import { BreakPointsKeys } from '../types';

/**
 * хелпер для получения текущего брейкпоинта по ширине браузера
 * @param windowWidth - Ширина браузера на основе котрой мы находи текущий брейкпоинт
 *
 * @returns - брейкпоинт соотвествующий ширине `windowWidth`
 */
export const getCurrentBreakPoint = (windowWidth: number) =>
  viewBreakPointsKeys.reduce(
    (
      currentBreakPoint: BreakPointsKeys,
      currentBreakPointKey
    ) => {
      if (
        viewBreakPoints[currentBreakPointKey] < windowWidth
      ) {
        return currentBreakPointKey;
      }

      return currentBreakPoint;
    },
    BreakPointsKeys.xxs
  );
