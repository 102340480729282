// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".By5qeXy{position:absolute;z-index:0;top:0;left:0;display:block;clip:rect(0, 0, 0, 0);width:1px;height:1px;pointer-events:none;touch-action:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectNative": "By5qeXy"
};
export default ___CSS_LOADER_EXPORT___;
