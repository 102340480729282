import cn from 'classnames';
import React, { type FC, type HTMLAttributes } from 'react';
import { Icon, Icons } from '../../Icon';
import { Link } from '../../Link';
import { formatPrice } from '../helpers/formatPrice';

import styles from './ProductAnalog.module.scss';

export interface ProductAnalogOptions {
  price: number;
}

export interface ProductAnalogProps
  extends ProductAnalogOptions,
    HTMLAttributes<HTMLDivElement> {}

export const ProductAnalog: FC<ProductAnalogProps> = ({
  price,
  ...rest
}) => {
  return (
    <Link className={cn(styles.Root)} {...rest}>
      Аналог от {formatPrice(price)}{' '}
      <Icon
        name={Icons.chevronRight}
        className={styles.Icon}
      />
    </Link>
  );
};
