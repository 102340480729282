import {
  useState,
  useCallback,
  KeyboardEvent
} from 'react';

import { UseSelectProps } from './types';

export const useSelect = ({
  value,
  isOpened
}: UseSelectProps) => {
  const [isExpanded, setIsExpanded] =
    useState<boolean>(isOpened);
  const [currentValue, setCurrentValue] =
    useState<string>(value);

  const expandHandler = useCallback(() => {
    setIsExpanded(true);
  }, []);

  const collapseHandler = useCallback(() => {
    setIsExpanded(false);
  }, []);

  const toggleHandler = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  const changeHandler = useCallback(
    (value: string) => {
      setCurrentValue(currentValue !== value ? value : '');
    },
    [currentValue]
  );

  const optionKeyDownHandler = (
    e: KeyboardEvent<HTMLButtonElement>,
    option: string
  ) => {
    switch (e.key) {
      case 'ArrowDown':
        if (e.currentTarget.nextSibling) {
          (
            e.currentTarget.nextSibling as HTMLButtonElement
          ).focus();
          break;
        }
        if (!e.currentTarget.nextSibling) {
          (
            e.currentTarget.parentNode
              ?.childNodes[0] as HTMLButtonElement
          ).focus();
          break;
        }
        break;

      case 'ArrowUp':
        if (e.currentTarget.previousSibling) {
          (
            e.currentTarget
              .previousSibling as HTMLButtonElement
          ).focus();
          break;
        }
        if (
          !e.currentTarget.previousSibling &&
          e.currentTarget.parentNode
        ) {
          const indexOfLastElement =
            e.currentTarget?.parentNode?.childNodes
              ?.length - 1;
          (
            e.currentTarget.parentNode?.childNodes[
              indexOfLastElement
            ] as HTMLButtonElement
          ).focus();
          break;
        }
        break;

      case 'Tab':
        break;

      case ' ':
      case 'Enter':
        changeHandler(option);
        collapseHandler();
        break;

      case 'Escape':
        collapseHandler();
        break;

      default:
        break;
    }
  };

  return {
    isExpanded,
    currentValue,
    expandHandler,
    collapseHandler,
    toggleHandler,
    changeHandler,
    optionKeyDownHandler
  };
};
