// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+fdR4pp{position:absolute;z-index:4;top:1px;right:1px;width:calc(100% - 2px);height:46px;border-radius:8px}.sUlIh1H{justify-content:flex-end;width:100%;height:100%;padding:0 12px 0 0;border-radius:8px}.sUlIh1H svg{border-radius:2px;fill:var(--color-graphite-sber-2);transition:transform .2s linear;will-change:transform}.FBhuV7k svg{transform:rotate(180deg)}.jFCHT7y{pointer-events:none;opacity:.5;background-color:var(--color-graphite-sber-4);touch-action:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectTrigger": "+fdR4pp",
	"SelectTriggerButton": "sUlIh1H",
	"SelectTriggerIsExpanded": "FBhuV7k",
	"SelectTriggerIsDisabled": "jFCHT7y"
};
export default ___CSS_LOADER_EXPORT___;
