import React, { FC, HTMLAttributes, useMemo } from 'react';
import cn from 'classnames';
import { Icon, Icons } from '../../Icon';
import { Link } from '../../Link';
import { Tooltip } from '../../Tooltip';
import {
  Typography,
  TypographySizingProps
} from '../../Typography';

import { SizingProps } from '../../../types/SizingProps';

import styles from './ProductInformer.module.scss';

export interface ProductInformerProps
  extends HTMLAttributes<HTMLDivElement> {
  tooltip?: string;
  iconName?: Icons;
  iconColor?: string;
  // TODO: нужно потом вернуть обратно
  // children: string;
  color?: string;
  link?: string;
}

export const ProductInformer: FC<ProductInformerProps> = ({
  className,
  tooltip,
  iconName,
  iconColor,
  children,
  color = '#576875',
  link,
  ...rest
}) => {
  const iconStyles = useMemo(
    () => (iconColor ? { fill: iconColor } : {}),
    [iconColor]
  );
  const textStyles = useMemo(() => ({ color }), [color]);
  const icon =
    iconName || ((tooltip ? 'info' : '') as Icons);

  return (
    <div className={cn(styles.Root, className)} {...rest}>
      {tooltip && (
        <Tooltip
          className={styles.tooltip}
          placement="top-start"
          text={tooltip}
        >
          <Icon
            size={SizingProps.s}
            className={styles.Icon}
            name={icon}
            style={iconStyles}
          />
        </Tooltip>
      )}
      {!tooltip && icon && (
        <Icon
          size={SizingProps.s}
          className={styles.Icon}
          name={icon}
          style={iconStyles}
        />
      )}
      {link ? (
        <Link
          className={styles.Link}
          href={link}
          style={textStyles}
        >
          {children}
        </Link>
      ) : (
        <Typography
          className={styles.Text}
          size={TypographySizingProps._14}
          style={textStyles}
        >
          {children}
        </Typography>
      )}
    </div>
  );
};
