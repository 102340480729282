// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QxHem-W{position:relative;overflow:hidden;width:100%;border:1px solid var(--color-graphite-sber-3);border-radius:8px;background-color:var(--color-white);transition:border-color .25s linear,background-color .25s linear}.QxHem-W::before,.QxHem-W::after{position:absolute;z-index:0;left:0;display:block;width:calc(100% - 7px);content:\"\";pointer-events:none;opacity:0;transition:opacity .2s linear,background .25s linear;touch-action:none}.QxHem-W::before{top:0;height:35px;background:linear-gradient(to bottom, rgb(255, 255, 255) 1%, rgb(255, 255, 255) 85%, rgba(255, 255, 255, 0) 100%)}.QxHem-W::after{bottom:0;height:13px;background:linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 60%, rgb(255, 255, 255) 100%)}._5fcWAWc{border-color:var(--color-pink-sber)}._5fcWAWc.Zv24o67{border-color:var(--color-pink-sber)}.Zv24o67{border-color:var(--color-violet)}.Zv24o67::before,.Zv24o67::after{opacity:1}.ZdJOs9r{pointer-events:none;background-color:var(--color-graphite-sber-3);touch-action:none}.ZdJOs9r._5fcWAWc,.ZdJOs9r.Zv24o67{border-color:var(--color-graphite-sber-3)}.ZdJOs9r textarea{color:var(--color-graphite-sber-2)}.ZdJOs9r::before{background:linear-gradient(to bottom, rgb(222, 225, 235) 1%, rgb(222, 225, 235) 85%, rgba(222, 225, 235, 0) 100%)}.ZdJOs9r::after{background:linear-gradient(to bottom, rgba(222, 225, 235, 0) 0%, rgb(222, 225, 235) 60%, rgb(222, 225, 235) 100%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextareaGroup": "QxHem-W",
	"TextareaGroupIsError": "_5fcWAWc",
	"TextareaGroupIsFocused": "Zv24o67",
	"TextareaGroupIsDisabled": "ZdJOs9r"
};
export default ___CSS_LOADER_EXPORT___;
