import { pluralizeWord } from './pluralizeWord';

const pluralizeWordVariants = [
  'бонус',
  'бонуса',
  'бонусов'
];
export const formatBonus = (bonus: number): string =>
  new Intl.NumberFormat('ru-RU').format(bonus) +
  ' ' +
  pluralizeWord(bonus, pluralizeWordVariants);
