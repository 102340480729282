import React, { FC, Ref, useRef } from 'react';
import cn from 'classnames';
import { useForkRef } from '../../../hooks/useForkRef';

import { InputFieldProps } from './types';

import { fieldSizesMap } from '.';

import styles from './InputField.module.scss';

export const InputField: FC<InputFieldProps> = ({
  id,
  controlRef,
  value,
  sizing,
  type,
  isError,
  isDisabled,
  isFocused,
  isReadonly,
  onChangeHandler,
  onFocus,
  onBlur
}) => {
  const _controlRef = useRef<HTMLInputElement>();
  const ownRef = useForkRef(_controlRef, controlRef);

  return (
    <input
      id={id}
      ref={ownRef as Ref<HTMLInputElement>}
      value={value}
      className={cn(styles.InputField, {
        [fieldSizesMap[sizing]]: sizing,
        [styles.InputFieldIsFocused]: isFocused,
        [styles.InputFieldIsReadonly]: isReadonly
      })}
      type={type}
      disabled={isDisabled}
      readOnly={isReadonly}
      aria-invalid={isError}
      onChange={(event) =>
        onChangeHandler(event.target.value)
      }
      onFocus={onFocus}
      onBlur={onBlur}
    />
  );
};
