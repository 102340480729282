import React, { FC, KeyboardEvent } from 'react';
import cn from 'classnames';

import { Button } from '../../Button';

import { SelectOptionProp } from './types';
import { SizingProps } from '../../../types/SizingProps';

import styles from './SelectOption.module.scss';

export const SelectOption: FC<SelectOptionProp> = ({
  id,
  value,
  isActive,
  onClickHandler,
  onKeyDownHandler
}) => {
  return (
    <Button
      id={id}
      className={cn(styles.SelectOption, {
        [styles.SelectOptionIsActive]: isActive
      })}
      size={SizingProps.l}
      role="option"
      aria-selected={isActive}
      onClick={() => onClickHandler(value)}
      onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) =>
        onKeyDownHandler(e, value)
      }
    >
      {value}
    </Button>
  );
};
