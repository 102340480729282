import React, { FC, HTMLAttributes } from 'react';
import { Icons } from '../../Icon';
import { ProductInformer } from './ProductInformer';

export const ProductInformerOriginal: FC<
  HTMLAttributes<HTMLDivElement>
> = (rest) => {
  return (
    <ProductInformer
      iconName={Icons.checkRound}
      iconColor="#31C2A7"
      {...rest}
    >
      Оригинальный препарат
    </ProductInformer>
  );
};
