// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6BmbRUg{display:inline-flex}.IhjKSVL{font-size:1rem}.IhjKSVL .mMtE66g:not(:last-child){margin-right:.125em}._5pKiE5H{font-size:1.5rem}._5pKiE5H .mMtE66g:not(:last-child){margin-right:.5em}.Sr0uOjc{font-size:2.5rem}.Sr0uOjc .mMtE66g:not(:last-child){margin-right:.6em}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Rating": "_6BmbRUg",
	"RatingSizeS": "IhjKSVL",
	"Star": "mMtE66g",
	"RatingSizeM": "_5pKiE5H",
	"RatingSizeL": "Sr0uOjc"
};
export default ___CSS_LOADER_EXPORT___;
