import {
  MouseEventHandler,
  ReactNode,
  RefObject
} from 'react';
import { PortalExtendableProps } from '../Portal';
import { LayerManagerExtendableProps } from '../LayerManager';

import { SizingProps } from '../../types/SizingProps';

export enum TModalAlignment {
  top = 'Top',
  middle = 'Middle',
  bottom = 'Bottom'
}

export type TModalClassNames = {
  overlay?: string;
  wrapper?: string;
  container?: string;
  content?: string;
  header?: string;
  footer?: string;
  close?: string;
  body?: string;
};

export interface IModalProps
  extends PortalExtendableProps,
    LayerManagerExtendableProps {
  /**
   * Выравнивание контента по вертикали
   *
   * @default 'middle'
   */
  contentVerticalAlign?: TModalAlignment;
  /**
   * Размер модального окна
   *
   * @default 'S'
   */
  size?: SizingProps;
  /**
   * Добавляет анимацию при открытии модального окна
   *
   * @default true
   */
  hasAnimation?: boolean;
  /**
   * Добавляет иконку закрытия модального окна
   *
   * @default true
   */
  hasCloseIcon?: boolean;
  /**
   * Отображение иконки закрытия модального окна внутри контентной области
   *
   * @default false
   */
  closeInsideContent?: boolean;
  /**
   * Блокирует прокрутку на теле документа, при открытом модальном окне
   *
   * @default true
   */
  preventBodyScroll?: boolean;
  /**
   * Дополнительный класс у корневого DOM-элемента
   */
  className?: string;
  /**
   * Дополнительные классы у остальных элементов компонента
   */
  classNames?: TModalClassNames;
  /**
   * Дополнительный класс у контейнера для контента модального окна
   */
  classNameContainer?: string;
  /**
   * Обработчик, вызываемый при срабатывании события click
   */
  onClick?: MouseEventHandler<HTMLDivElement>;
  /**
   * Ссылка на корневой DOM-элемент компонента
   */
  innerRef?: RefObject<HTMLDivElement>;
  /**
   * Ссылка на DOM элемент нативного контрола
   */
  controlRef?: RefObject<HTMLDivElement>;
  /**
   * Задает слой `z-index`
   */
  zIndex?: number;
  /**
   * Контентная область
   */
  children?: ReactNode;
  /**
   * Верхняя часть окна (заголовок, кнопки, иконки, описание)
   */
  header?: ReactNode;
  /**
   * Нижняя часть окна (в основном активные кнопки)
   */
  footer?: ReactNode;
}
