import React, { FC } from 'react';
import cn from 'classnames';
import { prettyWidth } from '../helpers/prettyWidth';
import type { IRatingStarProps } from '../types';
import stylesRating from '../Rating.module.scss';
import styles from './RatingStar.module.scss';

export const RatingStar: FC<IRatingStarProps> = ({
  fullness
}) => {
  const fullnessWidth: { width: string } = {
    width: prettyWidth(fullness) + '%'
  };
  const variant: 'Full' | 'Empty' =
    fullness === 1 ? 'Full' : 'Empty';

  return fullness > 0 && fullness < 1 ? (
    <span
      aria-hidden="true"
      className={cn(
        styles.Star,
        styles.EmptyVariant,
        stylesRating.Star
      )}
    >
      <span
        aria-hidden="true"
        className={cn(
          styles.Star,
          styles.FullVariant,
          stylesRating.Star
        )}
        style={fullnessWidth}
      />
    </span>
  ) : (
    <span
      aria-hidden="true"
      className={cn(
        styles.Star,
        styles[`${variant}Variant`],
        stylesRating.Star
      )}
    />
  );
};
