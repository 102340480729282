import React, { type FC } from 'react';
import clsx from 'clsx';

import { PaginationElement } from './PaginationElement';
import { PaginationArrow } from './PaginationArrow';

import { usePagination } from '../../hooks/usePagination';

import { setAriaLabel } from './helpers';

import {
  PaginationProps,
  PaginationPagesProps,
  paginationSizes
} from '.';

import { SizingProps } from '../../types/SizingProps';

import { Icons } from '../../../spriteImages/sprite';

import styles from './Pagination.module.scss';

export const Pagination: FC<PaginationProps> = ({
  page = 1,
  count = 1,
  boundaryCount = 1,
  siblingCount = 1,
  disabled = false,
  hasArrows = true,
  size = SizingProps.m,
  onChange,
  className,
  style,
  ...props
}) => {
  const { items } = usePagination({
    boundaryCount,
    count,
    disabled,
    onChange,
    page,
    siblingCount
  });

  return (
    <nav
      className={clsx(
        styles.Pagination,
        {
          [paginationSizes[size]]: size
        },
        className
      )}
      style={style}
      {...props}
    >
      {hasArrows && (
        <PaginationArrow
          goTo={page - 1}
          className={clsx(
            styles.PaginationButton,
            styles.PaginationButtonPrevious
          )}
          iconName={Icons.chevronBack}
          ariaLabel="На предыдущую страницу"
          isDisabled={page === 1 || disabled}
          onClick={onChange}
        />
      )}
      {items.map(
        (
          { type, page, selected, disabled, onClick },
          index
        ) => (
          <PaginationElement
            key={`page-${page}-${index}`}
            type={type as PaginationPagesProps}
            page={page}
            selected={selected}
            disabled={disabled}
            ariaLabel={setAriaLabel(
              type as PaginationPagesProps,
              page,
              selected
            )}
            className={styles.PaginationButton}
            onClick={onClick}
          />
        )
      )}
      {hasArrows && (
        <PaginationArrow
          goTo={page + 1}
          className={clsx(
            styles.PaginationButton,
            styles.PaginationButtonNext
          )}
          iconName={Icons.chevronRight}
          ariaLabel="На следующую страницу"
          isDisabled={page === count || disabled}
          onClick={onChange}
        />
      )}
    </nav>
  );
};
