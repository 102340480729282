import React, { FC, HTMLAttributes } from 'react';
import {
  Typography,
  TypographyTagProps,
  TypographyFontWeightProps
} from '../../Typography';
import styles from './ProductAttention.module.scss';

export interface ProductAttentionOptions {
  price?: string;
}

export interface ProductAttentionProps
  extends ProductAttentionOptions,
    HTMLAttributes<HTMLHeadingElement> {}

export const ProductAttention: FC<
  ProductAttentionProps
> = ({ ...rest }) => {
  return (
    <Typography
      tag={TypographyTagProps.h6}
      fontWeight={TypographyFontWeightProps.semiBold}
      className={styles.Root}
      {...rest}
    >
      Обратите внимание
    </Typography>
  );
};
