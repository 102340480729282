// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3R3lzyE{display:flex;flex-shrink:0;justify-content:center;align-items:center;width:24px;height:24px;padding:0;cursor:pointer;color:var(--color-graphite-sber-2);border:0;border-radius:50%;background:rgba(0,0,0,0);appearance:none}._3R3lzyE:hover{color:var(--color-graphite-sber-1)}._3R3lzyE:active{color:var(--color-graphite-sber)}._3R3lzyE svg{display:block;width:100%;height:100%;transition:all .2s ease}.lFJB2ks{color:var(--color-violet)}.lFJB2ks:hover{color:var(--color-violet-2)}.lFJB2ks:active{color:var(--color-violet-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "_3R3lzyE",
	"IsActive": "lFJB2ks"
};
export default ___CSS_LOADER_EXPORT___;
