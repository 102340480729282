import React, {
  ChangeEventHandler,
  FC,
  FocusEventHandler,
  Ref
} from 'react';
import cn from 'classnames';
import { CheckboxBox } from './Box/Checkbox-Box';
import { CheckboxControl } from './Control/Checkbox-Control';
import styles from './Checkbox.module.scss';

export interface ICheckbox {
  /**
   * Событие для изменения состояния чекбокса
   */
  onChange: ChangeEventHandler<HTMLInputElement>;

  /**
   * Значение чекбокса (выбран/не выбран)
   *
   * @default false
   */
  checked: boolean;

  /**
   * Имя для группы чекбоксов
   */
  name?: string;

  /**
   * Доступность для взаимодействия с чекбоксом
   */
  disabled?: boolean;

  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Индекс для табуляции
   *
   * @default 0
   */
  tabIndex?: number;

  /**
   * Автофокус при загрузке страницы
   *
   * @default false
   */
  autoFocus?: boolean;

  /**
   * Коллбэек на фокус
   *
   */
  onFocus?: FocusEventHandler<HTMLInputElement>;

  /**
   * Коллбэек на блюр
   *
   */
  onBlur?: FocusEventHandler<HTMLInputElement>;

  /**
   * Ссылка на корневой DOM элемент компонента
   */
  innerRef?: Ref<HTMLSpanElement>;

  /**
   * Ссылка на DOM элемент нативного контрола
   */
  controlRef?: Ref<HTMLInputElement>;
}

export const Checkbox: FC<ICheckbox> = ({
  checked = false,
  onChange,
  name,
  disabled = false,
  className,
  tabIndex,
  autoFocus,
  innerRef,
  controlRef,
  onFocus,
  onBlur,
  ...props
}: ICheckbox) => {
  return (
    <span
      className={cn(styles.Root, className)}
      ref={innerRef}
    >
      <CheckboxBox
        state={checked}
        aria-disabled={disabled}
      />
      <CheckboxControl
        onChange={onChange}
        name={name}
        disabled={disabled}
        checked={checked}
        autoFocus={autoFocus}
        tabIndex={tabIndex}
        controlRef={controlRef}
        onFocus={onFocus}
        onBlur={onBlur}
        {...props}
      />
    </span>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Checkbox.displayName = 'Checkbox';
}
