// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UA4K7jo{font-size:14px;font-weight:normal;line-height:21px;color:var(--color-graphite-sber-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Text": "UA4K7jo"
};
export default ___CSS_LOADER_EXPORT___;
