export enum Icons {
  'acne' = 'acne',
  'add' = 'add',
  'additionalOptions' = 'additionalOptions',
  'aidKit' = 'aidKit',
  'alcohol' = 'alcohol',
  'alfaBankColor' = 'alfaBankColor',
  'allergy' = 'allergy',
  'alphaBank' = 'alphaBank',
  'analytics' = 'analytics',
  'applePay' = 'applePay',
  'arrowBack' = 'arrowBack',
  'arrowDown' = 'arrowDown',
  'arrowEntrance' = 'arrowEntrance',
  'arrowForward' = 'arrowForward',
  'arrowUp' = 'arrowUp',
  'atom' = 'atom',
  'barCode' = 'barCode',
  'bell' = 'bell',
  'bnpl' = 'bnpl',
  'calendar' = 'calendar',
  'call' = 'call',
  'callback' = 'callback',
  'camera' = 'camera',
  'car' = 'car',
  'card' = 'card',
  'cardCross' = 'cardCross',
  'cardPlus' = 'cardPlus',
  'cart' = 'cart',
  'cash' = 'cash',
  'cashOnly' = 'cashOnly',
  'catalogue' = 'catalogue',
  'category' = 'category',
  'change' = 'change',
  'checkLarge' = 'checkLarge',
  'checkRound' = 'checkRound',
  'checkRoundBlack' = 'checkRoundBlack',
  'checkboxes' = 'checkboxes',
  'checkmarkAlt' = 'checkmarkAlt',
  'chevronBack' = 'chevronBack',
  'chevronDown' = 'chevronDown',
  'chevronRight' = 'chevronRight',
  'chevronUp' = 'chevronUp',
  'classifiers' = 'classifiers',
  'clip' = 'clip',
  'clockwise' = 'clockwise',
  'close' = 'close',
  'closeBigPage' = 'closeBigPage',
  'cold' = 'cold',
  'component' = 'component',
  'cookie' = 'cookie',
  'copy' = 'copy',
  'covid' = 'covid',
  'deleteRound' = 'deleteRound',
  'dimond' = 'dimond',
  'documentCheckmark' = 'documentCheckmark',
  'download' = 'download',
  'drug' = 'drug',
  'eRecipe' = 'eRecipe',
  'eapteka' = 'eapteka',
  'eaptekaIcon' = 'eaptekaIcon',
  'eaptekaLogoCross' = 'eaptekaLogoCross',
  'edit' = 'edit',
  'emias' = 'emias',
  'er' = 'er',
  'exclamation' = 'exclamation',
  'eye' = 'eye',
  'eyeClose' = 'eyeClose',
  'face' = 'face',
  'facebook' = 'facebook',
  'fastDelivery' = 'fastDelivery',
  'favoriteTabBarOnly' = 'favoriteTabBarOnly',
  'filters' = 'filters',
  'flame' = 'flame',
  'flashlight' = 'flashlight',
  'flower' = 'flower',
  'forYou' = 'forYou',
  'gPay' = 'gPay',
  'gear' = 'gear',
  'gift' = 'gift',
  'glass' = 'glass',
  'gpbBankColor' = 'gpbBankColor',
  'gpbBankMonochrome.svg' = 'gpbBankMonochrome.svg',
  'hand' = 'hand',
  'head' = 'head',
  'heart' = 'heart',
  'home' = 'home',
  'horizontally' = 'horizontally',
  'incomingCall' = 'incomingCall',
  'infoRound' = 'infoRound',
  'infoRoundFilled' = 'infoRoundFilled',
  'joint' = 'joint',
  'lamp' = 'lamp',
  'lightning' = 'lightning',
  'like' = 'like',
  'likeOn' = 'likeOn',
  'link' = 'link',
  'list' = 'list',
  'loader' = 'loader',
  'magicWand' = 'magicWand',
  'mail' = 'mail',
  'manBadge' = 'manBadge',
  'manSHealth' = 'manSHealth',
  'manWheelchair' = 'manWheelchair',
  'map' = 'map',
  'marker' = 'marker',
  'medicine' = 'medicine',
  'menopause' = 'menopause',
  'menu' = 'menu',
  'message' = 'message',
  'metro' = 'metro',
  'microphone' = 'microphone',
  'minus' = 'minus',
  'minusRound' = 'minusRound',
  'mobile' = 'mobile',
  'money' = 'money',
  'moneyHand' = 'moneyHand',
  'moreHorizontal' = 'moreHorizontal',
  'moreVertical' = 'moreVertical',
  'narcotic' = 'narcotic',
  'notebookWaves' = 'notebookWaves',
  'odnoklassniki' = 'odnoklassniki',
  'openBook' = 'openBook',
  'otkrBankColor' = 'otkrBankColor',
  'outgoingCall' = 'outgoingCall',
  'partners' = 'partners',
  'paw' = 'paw',
  'pencil' = 'pencil',
  'pencilOnly' = 'pencilOnly',
  'pharmacy' = 'pharmacy',
  'pharmacyBuilding' = 'pharmacyBuilding',
  'plus' = 'plus',
  'plusRound' = 'plusRound',
  'pochtabank' = 'pochtabank',
  'pochtabankBankColor' = 'pochtabankBankColor',
  'podborki' = 'podborki',
  'pointerForward' = 'pointerForward',
  'pram' = 'pram',
  'preOrder' = 'preOrder',
  'printer' = 'printer',
  'procent' = 'procent',
  'profile' = 'profile',
  'profileRound' = 'profileRound',
  'promocode' = 'promocode',
  'psychotropic' = 'psychotropic',
  'qrCode' = 'qrCode',
  'questionCircleFilled' = 'questionCircleFilled',
  'raiffeisen' = 'raiffeisen',
  'rosBankColor' = 'rosBankColor',
  'rosBankMonochrome' = 'rosBankMonochrome',
  'roundSpeechBubbleExclamation' = 'roundSpeechBubbleExclamation',
  'roundSpeechBubbleQuestion' = 'roundSpeechBubbleQuestion',
  'sale' = 'sale',
  'salePercent' = 'salePercent',
  'saleRound' = 'saleRound',
  'sberBankColor' = 'sberBankColor',
  'sberPay' = 'sberPay',
  'sberSpasibo' = 'sberSpasibo',
  'sberSpasiboHollow' = 'sberSpasiboHollow',
  'sberbank' = 'sberbank',
  'sbp' = 'sbp',
  'search' = 'search',
  'send' = 'send',
  'shareAndroid' = 'shareAndroid',
  'shareIos' = 'shareIos',
  'sorting' = 'sorting',
  'speech' = 'speech',
  'suggest' = 'suggest',
  'system' = 'system',
  'telegram' = 'telegram',
  'timeRound' = 'timeRound',
  'timeRoundBlack' = 'timeRoundBlack',
  'tinkoffBank' = 'tinkoffBank',
  'tinkoffBankColor' = 'tinkoffBankColor',
  'tinkoffBankDisabled' = 'tinkoffBankDisabled',
  'tooth' = 'tooth',
  'trash' = 'trash',
  'ukassa' = 'ukassa',
  'uralsibBankColor' = 'uralsibBankColor',
  'uralsibBankMonochrome' = 'uralsibBankMonochrome',
  'usualDelivery' = 'usualDelivery',
  'verticallyCard' = 'verticallyCard',
  'vip' = 'vip',
  'visa' = 'visa',
  'vk' = 'vk',
  'vtbBank' = 'vtbBank',
  'vtbBankColor' = 'vtbBankColor',
  'wallet' = 'wallet',
  'warningTriangle' = 'warningTriangle',
  'warningTriangleFilled' = 'warningTriangleFilled',
  'x2Bonus' = 'x2Bonus',
  'x2BonusFilled' = 'x2BonusFilled'
}