import { ChangeEventHandler } from 'react';

export const defaultValues = [
  { title: 'Отлично', value: 5 },
  { title: 'Хорошо', value: 4 },
  { title: 'Нормально', value: 3 },
  { title: 'Плохо', value: 2 },
  { title: 'Ужасно', value: 1 }
];

export const noop: ChangeEventHandler<
  HTMLInputElement
  // eslint-disable-next-line @typescript-eslint/no-empty-function
> = () => {};

export const hash: string = (Math.random() + 1)
  .toString(36)
  .substring(5);
