// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".VJPuqFs{display:flex;align-items:center;column-gap:8px;width:100%}._4ru0hb9,.HwS3kil{overflow:hidden;font-size:14px;font-weight:normal;line-height:21px;white-space:nowrap;text-overflow:ellipsis}.o-sNn-L{flex-shrink:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "VJPuqFs",
	"Link": "_4ru0hb9",
	"Text": "HwS3kil",
	"Icon": "o-sNn-L"
};
export default ___CSS_LOADER_EXPORT___;
