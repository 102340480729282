import React, { FC } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import type { Swiper as SwiperInstance } from 'swiper/types';
import { TSlideProp, ICarouselProps } from '../types';

import 'swiper/css';
import styles from './SwiperCarouselContainer.module.scss';
import arrowStyles from '../SwiperCarouselArrows/SwiperCarouselArrows.module.scss';

const navigation = {
  nextEl: `.${arrowStyles.RootNext}`,
  prevEl: `.${arrowStyles.RootPrev}`
};

const SwiperCarouselContainer: FC<ICarouselProps> = ({
  slides,
  slidesPerView,
  slidesPerGroup,
  autoplay,
  autoplayDelay,
  autoplayDisableOnInteraction,
  autoplayPauseOnMouseEnter,
  spaceBetween,
  loop,
  breakpoints,
  onInit,
  onChange,
  style = {},
  ...props
}) => {
  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      className={styles.Root}
      slidesPerView={slidesPerView}
      slidesPerGroup={slidesPerGroup}
      navigation={navigation}
      spaceBetween={spaceBetween}
      autoplay={{
        enabled: autoplay,
        delay: autoplayDelay,
        disableOnInteraction: autoplayDisableOnInteraction,
        pauseOnMouseEnter: autoplayPauseOnMouseEnter
      }}
      loop={loop}
      breakpoints={breakpoints}
      watchSlidesProgress
      style={style}
      onSwiper={(swiper: SwiperInstance) =>
        onInit && onInit(swiper)
      }
      onSlideChange={(swiper: SwiperInstance) =>
        onChange && onChange(swiper)
      }
      {...props}
    >
      {slides.map((slide: TSlideProp, index: number) => (
        <SwiperSlide
          key={`carousel-slide-${index}`}
          className={styles.Slide}
        >
          {slide}
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperCarouselContainer;
