// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+hj6D2z{margin-top:0;margin-bottom:0;color:var(--color-graphite-sber)}.\\+hj6D2z a{font-size:inherit}.qftvCic{font-family:var(--display-font-family)}.s4Wz6MG{font-family:var(--control-font-family)}.\\+APDdOJ{font-weight:normal}.WbEQlH8{font-weight:600}.yFkHlVu{font-size:72px;line-height:76px}.QG6yJ6f{font-size:64px;line-height:66px}.jkdhP2M{font-size:56px;line-height:62px}._8HkzJg2{font-size:48px;line-height:54px}.LWJTlF-{font-size:40px;line-height:48px}.CH-YMOp{font-size:32px;line-height:40px}.LzUCgEE{font-size:28px;line-height:34px}.eGdlICZ{font-size:24px;line-height:30px}.I6EqWfv{font-size:22px;line-height:28px}.o3B2pkc{font-size:20px;line-height:26px}.y63UsfC{font-size:18px;line-height:22px}._6xUIR6i{font-size:16px;line-height:20px}.ddTzBW6{font-size:14px;line-height:18px}@media all and (max-width: 991px){.fKEDGQP{font-size:72px;line-height:76px}.qdrjlF0{font-size:64px;line-height:66px}.f8sjFLv{font-size:56px;line-height:62px}._9MVZyT\\+{font-size:48px;line-height:54px}._8qGwvZs{font-size:40px;line-height:48px}.lPDx-gw{font-size:32px;line-height:40px}.h2G\\+TiK{font-size:28px;line-height:34px}.rYrZ1b9{font-size:24px;line-height:30px}.UW2rhNI{font-size:22px;line-height:28px}.Igfaeff{font-size:20px;line-height:26px}._0gwuK79{font-size:18px;line-height:22px}.KJB-RXN{font-size:16px;line-height:20px}.srFgkcT{font-size:14px;line-height:18px}}@media all and (max-width: 767px){.ClLBLiL{font-size:72px;line-height:76px}.YsxeQyd{font-size:64px;line-height:66px}.eO6CUHN{font-size:56px;line-height:62px}.MwZV16r{font-size:48px;line-height:54px}.tbahpu1{font-size:40px;line-height:48px}.SUsz9Y9{font-size:32px;line-height:40px}.IXzwLj7{font-size:28px;line-height:34px}._1b9fPX-{font-size:24px;line-height:30px}.MX8UFSV{font-size:22px;line-height:28px}.d\\+HyH\\+P{font-size:20px;line-height:26px}.GcsIqFC{font-size:18px;line-height:22px}.zoxPKMA{font-size:16px;line-height:20px}.KNZp-oS{font-size:14px;line-height:18px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "+hj6D2z",
	"FontSBDisplay": "qftvCic",
	"FontSBText": "s4Wz6MG",
	"Font400": "+APDdOJ",
	"Font600": "WbEQlH8",
	"Size72": "yFkHlVu",
	"Size64": "QG6yJ6f",
	"Size56": "jkdhP2M",
	"Size48": "_8HkzJg2",
	"Size40": "LWJTlF-",
	"Size32": "CH-YMOp",
	"Size28": "LzUCgEE",
	"Size24": "eGdlICZ",
	"Size22": "I6EqWfv",
	"Size20": "o3B2pkc",
	"Size18": "y63UsfC",
	"Size16": "_6xUIR6i",
	"Size14": "ddTzBW6",
	"SizeTablet72": "fKEDGQP",
	"SizeTablet64": "qdrjlF0",
	"SizeTablet56": "f8sjFLv",
	"SizeTablet48": "_9MVZyT+",
	"SizeTablet40": "_8qGwvZs",
	"SizeTablet32": "lPDx-gw",
	"SizeTablet28": "h2G+TiK",
	"SizeTablet24": "rYrZ1b9",
	"SizeTablet22": "UW2rhNI",
	"SizeTablet20": "Igfaeff",
	"SizeTablet18": "_0gwuK79",
	"SizeTablet16": "KJB-RXN",
	"SizeTablet14": "srFgkcT",
	"SizeMobile72": "ClLBLiL",
	"SizeMobile64": "YsxeQyd",
	"SizeMobile56": "eO6CUHN",
	"SizeMobile48": "MwZV16r",
	"SizeMobile40": "tbahpu1",
	"SizeMobile32": "SUsz9Y9",
	"SizeMobile28": "IXzwLj7",
	"SizeMobile24": "_1b9fPX-",
	"SizeMobile22": "MX8UFSV",
	"SizeMobile20": "d+HyH+P",
	"SizeMobile18": "GcsIqFC",
	"SizeMobile16": "zoxPKMA",
	"SizeMobile14": "KNZp-oS"
};
export default ___CSS_LOADER_EXPORT___;
