import {
  Ref,
  FocusEventHandler,
  ReactNode,
  CSSProperties
} from 'react';

import { SizingProps } from '../../types/SizingProps';

export enum InputTypesProps {
  'text' = 'text',
  'email' = 'email',
  'password' = 'password',
  'tel' = 'tel',
  'date' = 'date',
  'number' = 'number'
}

export type InputIsErrorProp = boolean;

export type InputIsFocusedProp = boolean;

export type InputIsDisabledProp = boolean;

export type InputIsReadonlyProp = boolean;

export type InputHasClearButtonProp = boolean;

export type InputValueProp = string;

export interface InputControlRefProp {
  current: HTMLInputElement | null;
}

export type InputOnChangeProp = (arg: string) => void;

export type InputOnFocusProp =
  FocusEventHandler<HTMLInputElement>;

export type InputOnBlurProp =
  FocusEventHandler<HTMLInputElement>;

export type InputAddonProp = ReactNode;

export interface InputProps {
  value: InputValueProp;
  controlRef?: InputControlRefProp;
  innerRef?: Ref<HTMLDivElement>;
  className?: string;
  inputGroupClassName?: string;
  placeholder?: string;
  isError?: InputIsErrorProp;
  errorText?: string;
  description?: string;
  isDisabled?: InputIsDisabledProp;
  type?: InputTypesProps;
  sizing?: SizingProps;
  hasClearButton?: InputHasClearButtonProp;
  isReadonly?: InputIsReadonlyProp;
  addonAfter?: InputAddonProp;
  style?: CSSProperties;
  onChangeHandler?: InputOnChangeProp;
  onFocusHandler?: InputOnFocusProp;
  onBlurHandler?: InputOnBlurProp;
}
