// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._3tpv9zm{--border-width: 1px;position:relative;display:flex;justify-content:center;align-items:center;box-sizing:border-box;width:100%;cursor:pointer;white-space:nowrap;border:solid var(--border-width) rgba(0,0,0,0);border-radius:8px;background:#fff;background-clip:padding-box;aspect-ratio:1/1}.u0l5mMA::before{background-image:var(--gradient-bonus-eapteka)}._3tpv9zm::before{position:absolute;z-index:-1;inset:0;margin:calc(var(--border-width)*-1);content:\"\";border-radius:inherit}.LdHZhYV{max-width:100%;height:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "_3tpv9zm",
	"Root--gradientBorder": "u0l5mMA",
	"Image": "LdHZhYV"
};
export default ___CSS_LOADER_EXPORT___;
