import cn from 'classnames';
import React, { FC, ImgHTMLAttributes } from 'react';
import styles from './ProductImage.module.scss';

export type ProductImageProps =
  ImgHTMLAttributes<HTMLImageElement> & {
    withGradient?: boolean;
  };

export const ProductImage: FC<ProductImageProps> = ({
  alt,
  withGradient = false,
  ...rest
}) => {
  const rootClassName = cn(styles.Root, {
    [styles['Root--gradientBorder']]: withGradient
  });

  return (
    <picture className={rootClassName}>
      <img className={styles.Image} alt={alt} {...rest} />
    </picture>
  );
};
