/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, {
  useState,
  useRef,
  FC,
  useEffect,
  useCallback
} from 'react';
import { createPortal } from 'react-dom';
import { useTooltipPosition } from './helper';
import { TooltipProperties } from './types';
import styles from './Tooltip.module.scss';

export const Tooltip: FC<TooltipProperties> = ({
  text,
  children,
  placement = 'top',
  className,
  content,
  isAlwaysVisible = false
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLDivElement | null>(null);
  const rootClass = className
    ? `${styles.Tooltip} ${className}`
    : styles.Tooltip;

  const handleMouseEnter = () => {
    setIsVisible(true);
  };

  const handleMouseLeave = useCallback(() => {
    if (isAlwaysVisible) return;
    setIsVisible(false);
  }, [isAlwaysVisible]);

  const position = useTooltipPosition(
    tooltipRef,
    triggerRef,
    placement,
    isVisible
  );

  useEffect(() => {
    if (isAlwaysVisible) {
      handleMouseEnter();
    } else {
      handleMouseLeave();
    }
  }, [handleMouseLeave, isAlwaysVisible]);

  return (
    <div
      ref={triggerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={rootClass}
      role="tooltip"
      data-placement={position}
      data-visible={isVisible}
    >
      {children}
      {isVisible &&
        createPortal(
          <div ref={tooltipRef} className={styles.Wrapper}>
            {text && (
              <span className={styles.Text}>{text}</span>
            )}
            {content}
          </div>,
          document.body
        )}
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Tooltip.displayName = 'Tooltip';
}
