import cn from 'classnames';
import React, { FC } from 'react';

import type { IRatingBaseProps } from '../types';
import { RatingStar } from '../Star/RatingStar';

import styles from './RatingNumber.module.scss';

export const RatingNumber: FC<IRatingBaseProps> = ({
  max = 5,
  filled = true,
  value,
  className,
  style,
  innerRef,
  controlRef,
  tag: Component = 'div',
  ...props
}) => {
  const rating = `Рейтинг ${Math.min(value, max)} из ${max}`;
  const fullness: number = filled ? 1 : value / max;
  const preparedValue: string = value
    .toFixed(1)
    .toString()
    .replace('.', ',');

  return (
    <Component
      className={cn(styles.RatingNumber, className)}
      style={style}
      ref={innerRef || controlRef}
      role="img"
      aria-label={rating}
      {...props}
    >
      <RatingStar fullness={fullness} />
      <span aria-hidden="true" className={styles.Value}>
        {preparedValue}
      </span>
    </Component>
  );
};

if (process.env.NODE_ENV !== 'production') {
  RatingNumber.displayName = 'RatingNumber';
}
