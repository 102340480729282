// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".xozQkJs{font-size:14px;line-height:21px;color:var(--color-graphite-sber-1)}.f3L3rNS{margin-right:4px}.BXxSvQT{color:inherit}.BXxSvQT:hover{color:var(--color-violet)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "xozQkJs",
	"Label": "f3L3rNS",
	"Link": "BXxSvQT"
};
export default ___CSS_LOADER_EXPORT___;
