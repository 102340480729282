// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".XCY6mnW{position:absolute;z-index:1;top:0;left:0;display:block;overflow:hidden;align-items:center;width:calc(100% - 7px);height:34px;padding:16px 16px 0;font-size:14px;font-weight:normal;line-height:18px;user-select:none;white-space:nowrap;text-overflow:ellipsis;pointer-events:none;color:var(--color-graphite-sber-2);background-color:var(--color-white);transition:all .25s linear;touch-action:none}.FftmVpL{height:30px;font-size:12px;line-height:14px;color:var(--color-violet)}.FftmVpL.xusd8NN{color:var(--color-graphite-sber-2)}.xusd8NN{color:var(--color-graphite-sber-2);background-color:var(--color-graphite-sber-3)}._2d\\+Q4Ee{color:var(--color-pink-sber)}._2d\\+Q4Ee.xusd8NN{color:var(--color-graphite-sber-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextareaPlaceholder": "XCY6mnW",
	"TextareaPlaceholderIsFocused": "FftmVpL",
	"TextareaPlaceholderIsDisabled": "xusd8NN",
	"TextareaPlaceholderIsError": "_2d+Q4Ee"
};
export default ___CSS_LOADER_EXPORT___;
