import { SizingProps } from '../../types/SizingProps';
import Styles from '../Preloader/Preloader.module.scss';

export interface PreloaderSizesMapProps {
  [index: string]: string;
}

export const preloaderSizesMap = {
  [SizingProps.s]: Styles.RootSizeS,
  [SizingProps.m]: Styles.RootSizeM,
  [SizingProps.l]: Styles.RootSizeL
} as PreloaderSizesMapProps;
