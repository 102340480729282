import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import styles from './Product.module.scss';

export const ProductBody: FC<
  HTMLAttributes<HTMLDivElement>
> = ({ children, className, ...rest }) => {
  return (
    <div
      {...rest}
      className={cn(styles.ProductBody, className)}
    >
      {children}
    </div>
  );
};
