import React, { FC } from 'react';
import cn from 'classnames';

import { InputErrorProps } from './types';

import styles from './InputError.module.scss';

export const InputError: FC<InputErrorProps> = ({
  error,
  isDisabled,
  isFocused
}) => {
  return (
    <div
      className={cn(styles.InputError, {
        [styles.InputErrorIsFocused]: isFocused,
        [styles.InputErrorIsDisabled]: isDisabled
      })}
    >
      {error}
    </div>
  );
};
