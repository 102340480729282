import React from 'react';
import cn from 'classnames';
import { Button, ButtonThemes } from '../../Button';
import { Icon, Icons } from '../../Icon';

import { SizingProps } from '../../../types/SizingProps';

import styles from './SwiperCarouselArrows.module.scss';

const SwiperCarouselArrows = () => {
  return (
    <>
      <Button
        className={cn(styles.Root, styles.RootPrev)}
        size={SizingProps.m}
        theme={ButtonThemes.tertiary}
        aria-label="Предыдущий слайд"
        icon
      >
        <Icon name={Icons.chevronBack} />
      </Button>
      <Button
        className={cn(styles.Root, styles.RootNext)}
        size={SizingProps.m}
        theme={ButtonThemes.tertiary}
        aria-label="Следующий слайд"
        icon
      >
        <Icon name={Icons.chevronRight} />
      </Button>
    </>
  );
};

export default SwiperCarouselArrows;
