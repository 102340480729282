// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".KvWn2Tz{position:relative;width:100%}._5GzOPkW>div{overflow:visible}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "KvWn2Tz",
	"RootNoOverflow": "_5GzOPkW"
};
export default ___CSS_LOADER_EXPORT___;
