import React, { type FC } from 'react';
import cn from 'classnames';

import {
  Typography,
  TypographySizingProps
} from '../../Typography';

import { TextareaErrorProps } from '.';

import styles from './TextareaError.module.scss';

export const TextareaError: FC<TextareaErrorProps> = ({
  errorText,
  isError,
  isDisabled,
  isFocused
}) => {
  return (
    <Typography
      className={cn(styles.TextareaError, {
        [styles.TextareaErrorIsFocused]: isFocused,
        [styles.TextareaErrorIsDisabled]: isDisabled,
        [styles.TextareaErrorIsError]: isError
      })}
      size={TypographySizingProps._14}
    >
      {errorText}
    </Typography>
  );
};
