// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".k6DSbGl{position:relative;box-sizing:border-box;width:100%}.k6DSbGl *{box-sizing:border-box}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Input": "k6DSbGl"
};
export default ___CSS_LOADER_EXPORT___;
