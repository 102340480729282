import { useState, type FocusEventHandler } from 'react';

type IInputUseInputText = {
  onFocus?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
  onBlur?: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  >;
};
/**
 * Состояние фокуса для инпута
 */
interface IUseInputText {
  (_input?: IInputUseInputText): {
    hasFocus: boolean;
    onFocus: FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    >;
    onBlur: FocusEventHandler<
      HTMLInputElement | HTMLTextAreaElement
    >;
  };
}

export const useInputText: IUseInputText = (input) => {
  const [hasFocus, setHasFocus] = useState(false);
  const handleFocus: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setHasFocus(true);
    if (input?.onFocus) {
      input.onFocus(e);
    }
  };
  const handleBlur: FocusEventHandler<
    HTMLInputElement | HTMLTextAreaElement
  > = (e) => {
    setHasFocus(false);
    if (input?.onBlur) {
      input.onBlur(e);
    }
  };

  return {
    hasFocus,
    onFocus: handleFocus,
    onBlur: handleBlur
  };
};
