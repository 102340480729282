// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sadCoeH{position:relative;box-sizing:border-box;width:var(--checkbox-box-size);min-width:var(--checkbox-box-size);height:var(--checkbox-box-size);cursor:pointer;border:2px solid var(--color-graphite-sber-2);border-radius:4px;outline-offset:2px;background:rgba(0,0,0,0);transition:.25s cubic-bezier(0.4, 0, 0.23, 1)}.sadCoeH:active{border:var(--checkbox-box-border-active-width) solid var(--color-violet-1)}.sadCoeH[aria-checked=true]{border:var(--checkbox-box-border-active-width) solid var(--color-violet)}.sadCoeH[aria-checked=true]:active{border:var(--checkbox-box-border-active-width) solid var(--color-violet-1)}.sadCoeH[aria-disabled=true]{pointer-events:none;opacity:.4;border:2px solid var(--color-graphite-sber-2)}.sadCoeH[aria-disabled=true][aria-checked=true]{background-color:var(--color-graphite-sber-2)}.MwF2J22{position:absolute;top:50%;left:50%;transform:translate(-50%, -50%)}.zmliOgJ{stroke-dasharray:17.8;stroke-dashoffset:17.8}.sadCoeH[aria-checked=true] .zmliOgJ{animation:ymQoXdW .25s cubic-bezier(0.4, 0, 0.23, 1) .15s forwards}.sadCoeH[aria-checked=false] .zmliOgJ{animation:NFhEY8e .15s cubic-bezier(1, 0.23, 0, 0.4) .25s backwards}@keyframes ymQoXdW{to{stroke-dashoffset:0}}@keyframes NFhEY8e{to{stroke-dashoffset:17.8}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "sadCoeH",
	"BoxCheckmarkContainer": "MwF2J22",
	"Checkmark": "zmliOgJ",
	"checkboxCheck": "ymQoXdW",
	"checkboxUncheck": "NFhEY8e"
};
export default ___CSS_LOADER_EXPORT___;
