// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X\\+-F9Gs{position:absolute;z-index:1;box-sizing:border-box;width:44px;height:24px;margin:0;cursor:pointer;opacity:0}.X\\+-F9Gs:focus-visible+span{outline-width:2px;outline-style:dashed;outline-offset:2px}.X\\+-F9Gs:focus-visible+span[data-variant=default]{outline-color:var(--color-violet)}.X\\+-F9Gs:focus-visible+span[data-variant=green]{outline-color:var(--color-sberspasibo)}.X\\+-F9Gs:hover+span[data-variant=default],.X\\+-F9Gs:hover+span[data-variant=green]{background-color:var(--color-graphite-sber-1)}.X\\+-F9Gs:hover+span[aria-checked=true][data-variant=default]{background-color:var(--color-violet-2)}.X\\+-F9Gs:hover+span[aria-checked=true][data-variant=green]{background-color:var(--color-sberspasibo-1)}.X\\+-F9Gs:active+span[aria-checked=true][data-variant=default]{background-color:var(--color-violet-1)}.X\\+-F9Gs:active+span[aria-checked=true][data-variant=green]{background-color:var(--color-sberspasibo-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Control": "X+-F9Gs"
};
export default ___CSS_LOADER_EXPORT___;
