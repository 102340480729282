import { SizingProps } from '../../../types/SizingProps';

import styles from '../../Input/InputField/InputField.module.scss';

export interface FieldSizesMapProps {
  [index: string]: string;
}

export const fieldSizesMap = {
  [SizingProps.m]: styles.InputFieldSizeM,
  [SizingProps.l]: styles.InputFieldSizeL
} as FieldSizesMapProps;
