import { MutableRefObject } from 'react';

// simplified commitAttachRef from https://github.com/facebook/react/blob/1b752f1914b677c0bb588d10a40c12f332dfd031/packages/react-reconciler/src/ReactFiberCommitWork.js#L693
export function setRef<T>(
  ref:
    | MutableRefObject<T | null>
    | ((_instance: T | null) => void)
    | null
    | undefined,
  value: T | null
): void {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
}
