// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._6DyRn6G{position:relative;z-index:3;flex-shrink:0}._6DyRn6G svg{fill:var(--color-graphite-sber-2)}._3tn9tdN{opacity:.5}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputClearButton": "_6DyRn6G",
	"InputClearButtonIsDisabled": "_3tn9tdN"
};
export default ___CSS_LOADER_EXPORT___;
