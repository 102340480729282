// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PimnXnW{display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:3;overflow:hidden;font-size:16px;font-weight:600;line-height:22px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "PimnXnW"
};
export default ___CSS_LOADER_EXPORT___;
