import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import {
  Typography,
  TypographySizingProps,
  TypographyTagProps
} from '../../Typography';

import styles from './ProductBadge.module.scss';

export interface ProductBadgeOptions {
  color?: string;
  top?: boolean;
  bottom?: boolean;
  left?: boolean;
  right?: boolean;
}

export interface ProductBadgeProps
  extends ProductBadgeOptions,
    HTMLAttributes<HTMLHeadingElement> {}

export const ProductBadge: FC<ProductBadgeProps> = ({
  color = '#FF1F78',
  top,
  bottom,
  left,
  right,
  ...rest
}) => {
  const style = {
    backgroundColor: color,
    top: top ? 0 : 'auto',
    bottom: bottom ? 0 : 'auto',
    left: left ? 0 : 'auto',
    right: right ? 0 : 'auto'
  };

  return (
    <Typography
      tag={TypographyTagProps.h6}
      size={TypographySizingProps._16}
      style={style}
      className={cn(styles.Root)}
      {...rest}
    />
  );
};
