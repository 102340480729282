import React, { type FC } from 'react';
import cn from 'classnames';

import { Icon } from '../../Icon';

import { PaginationArrowProps } from '.';

import styles from './PaginationArrow.module.scss';

export const PaginationArrow: FC<PaginationArrowProps> = ({
  goTo,
  className,
  iconName,
  isDisabled,
  ariaLabel,
  onClick
}) => {
  return (
    <button
      aria-label={ariaLabel}
      className={cn(
        styles.PaginationArrow,
        {
          [styles.PaginationArrowIsDisabled]: isDisabled
        },
        className
      )}
      type="button"
      onClick={(e) => onClick(e, goTo)}
    >
      <Icon name={iconName} />
    </button>
  );
};
