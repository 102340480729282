// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EKIX9gL{display:flex;align-items:center;width:100%;gap:0 4px}.hnO4jpA{display:flex}.MhCXRkH .hnO4jpA{min-width:32px;height:32px}.KBTEVBe .hnO4jpA{min-width:24px;height:24px}.DxZpRmp{padding-right:2px}.ggzti31{padding-left:2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Pagination": "EKIX9gL",
	"PaginationButton": "hnO4jpA",
	"PaginationSizeM": "MhCXRkH",
	"PaginationSizeS": "KBTEVBe",
	"PaginationButtonPrevious": "DxZpRmp",
	"PaginationButtonNext": "ggzti31"
};
export default ___CSS_LOADER_EXPORT___;
