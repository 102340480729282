// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mP0ExqT{position:absolute;z-index:1;top:0;left:0;display:block;overflow:hidden;align-items:center;width:calc(100% - 7px);height:34px;padding:16px 16px 0;font-weight:normal;user-select:none;white-space:nowrap;text-overflow:ellipsis;pointer-events:none;color:var(--color-pink-sber);background-color:var(--color-white);transition:all .25s linear;touch-action:none}.bO6eiSI{height:30px;font-size:12px;line-height:16px}.jun9pjF{color:var(--color-graphite-sber-2)}.jun9pjF.uC0G3GD{background-color:var(--color-graphite-sber-3)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextareaError": "mP0ExqT",
	"TextareaErrorIsFocused": "bO6eiSI",
	"TextareaErrorIsDisabled": "jun9pjF",
	"TextareaErrorIsError": "uC0G3GD"
};
export default ___CSS_LOADER_EXPORT___;
