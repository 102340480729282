import React, { FC } from 'react';
import cn from 'classnames';

import { InputAddonProps } from './types';

import styles from './InputAddon.module.scss';

export const InputAddon: FC<InputAddonProps> = ({
  isDisabled,
  children
}) => {
  return (
    <div
      className={cn(styles.InputAddon, {
        [styles.InputAddonIsDisabled]: isDisabled
      })}
    >
      {children}
    </div>
  );
};
