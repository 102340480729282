import React, { FC } from 'react';
import cn from 'classnames';

import { SelectDropdownProps } from '.';

import styles from './SelectDropdown.module.scss';

export const SelectDropdown: FC<SelectDropdownProps> = ({
  children,
  isError
}) => {
  return (
    <div
      className={cn(styles.SelectDropdown, {
        [styles.SelectDropdownIsError]: isError
      })}
      role="listbox"
      tabIndex={-1}
    >
      {children}
    </div>
  );
};
