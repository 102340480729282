import React, { FC } from 'react';
import cn from 'classnames';

import { Button } from '../../Button';
import { Icon, Icons } from '../../Icon';

import { SelectTriggerProps } from '.';

import styles from './SelectTrigger.module.scss';

export const SelectTrigger: FC<SelectTriggerProps> = ({
  isExpanded,
  isDisabled,
  onExpandHandler
}) => {
  return (
    <div
      className={cn(styles.SelectTrigger, {
        [styles.SelectTriggerIsExpanded]: isExpanded,
        [styles.SelectTriggerIsDisabled]: isDisabled
      })}
    >
      <Button
        className={styles.SelectTriggerButton}
        icon
        onClick={onExpandHandler}
        role="combobox"
        aria-haspopup="listbox"
        aria-expanded={isExpanded}
        tabIndex={-1}
      >
        <Icon name={Icons.chevronDown} />
      </Button>
    </div>
  );
};
