import React, {
  CSSProperties,
  ReactNode,
  Ref,
  type FC,
  type ElementType
} from 'react';
import cn from 'classnames';

import styles from './Card.module.scss';

export const CardTags = {
  div: 'div' as const,
  article: 'article' as const
};
type ICardTags = keyof typeof CardTags;

export interface ICardProps {
  /**
   * Кастомные классы для корневого элемента
   */
  className?: string;
  /**
   * CSS-стили
   */
  style?: CSSProperties;
  /**
   * Ссылка на корневой DOM элемент компонента
   */
  innerRef?: Ref<HTMLDivElement>;
  /**
   * Ссылка на DOM элемент нативного контрола
   */
  controlRef?: Ref<HTMLAnchorElement>;
  /**
   * Значение href
   */
  to?: string;
  /**
   * Значение rel
   */
  rel?: string;
  /**
   * Значение target
   */
  target?: string;
  /**
   * Референс
   */
  ariaLabelledBy?: string;
  /**
   * Тег для отрисовки компонента
   *
   * @default 'div'
   */
  tag?: ICardTags | ReactNode;
  /**
   * Компонент ссылки
   */
  linkComponent?: ElementType;
}

/**
 * Компонент-обёртка для карточки товара
 * @interface ICardProps
 * @author m.mayorov
 */
export const Card: FC<ICardProps> = ({
  className,
  style,
  innerRef,
  controlRef,
  to,
  rel,
  target,
  ariaLabelledBy,
  children,
  tag = CardTags.div,
  linkComponent = 'a'
}) => {
  const RootTag = tag as ICardTags;
  const LinkTag = linkComponent;
  const linkHrefProp = LinkTag === 'a' ? 'href' : 'to';
  return (
    <RootTag
      className={cn(styles.Root, className)}
      style={style}
      ref={innerRef}
    >
      {to && (
        <LinkTag
          ref={controlRef}
          {...{ [linkHrefProp]: to }}
          rel={rel}
          target={target}
          aria-labelledby={ariaLabelledBy}
          className={styles.Link}
        />
      )}
      {children}
    </RootTag>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Card.displayName = 'Card';
}
