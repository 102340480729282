import React, { FC } from 'react';

import { Root } from '../SelectGroup';

import { SelectListProps } from './types';

import styles from './SelectList.module.scss';

export const SelectList: FC<SelectListProps> = ({
  options,
  currentValue,
  onChangeHandler,
  onExpandHandler,
  onKeyDownHandler
}) => {
  return (
    <div className={styles.SelectList}>
      {options.map(({ id, value }) => (
        <Root.Option
          key={id}
          id={id}
          value={value}
          isActive={currentValue === value}
          onClickHandler={(value: string) => {
            onChangeHandler(
              value !== currentValue ? value : ''
            );
            onExpandHandler();
          }}
          onKeyDownHandler={onKeyDownHandler}
        />
      ))}
    </div>
  );
};
