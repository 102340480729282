import cn from 'classnames';
import React, { forwardRef, HTMLAttributes } from 'react';
import { Skeleton } from '../Skeleton';
import { ProductHeader } from './ProductHeader';
import { ProductFooter } from './ProductFooter';
import { ProductBody } from './ProductBody';

import styles from './Product.module.scss';

export enum Directions {
  'column' = 'column',
  'row' = 'row'
}

export interface ProductOptions {
  direction?: Directions;
  isLoading?: boolean;
}

export interface ThemeOptions {
  /**
   * The flex direction of the card
   */
  size?: 's' | 'm';
}

export const StylesMap = {
  [Directions.column]: styles.Column,
  [Directions.row]: styles.Row
};

export interface ProductRootProps
  extends ProductOptions,
    ThemeOptions,
    HTMLAttributes<HTMLDivElement> {}

export const ProductRoot = forwardRef<
  HTMLDivElement,
  ProductRootProps
>(function ProductRoot(props, ref) {
  const {
    direction = 'column',
    children,
    className,
    isLoading = false,
    ...rest
  } = props;

  if (isLoading) {
    return (
      <div
        ref={ref}
        {...rest}
        className={cn(
          styles.ProductRoot,
          className,
          StylesMap[direction]
        )}
      >
        <ProductHeader className={styles.Skeleton}>
          <Skeleton className={styles.SkeletonImage} />
        </ProductHeader>
        <ProductBody className={styles.Skeleton}>
          <Skeleton className={styles.SkeletonTitle} />
          <Skeleton className={styles.SkeletonText} />
          <Skeleton className={styles.SkeletonText} />
          <Skeleton
            className={cn(
              styles.SkeletonText,
              styles.SkeletonTextSmall
            )}
          />
          <Skeleton className={styles.SkeletonText} />
        </ProductBody>
        <ProductFooter className={styles.Skeleton}>
          <Skeleton className={styles.SkeletonPrice} />
          <Skeleton className={styles.SkeletonPrice} />
          <Skeleton className={styles.SkeletonBonus} />
          <Skeleton className={styles.SkeletonButton} />
          <Skeleton />
        </ProductFooter>
      </div>
    );
  }

  return (
    <div
      ref={ref}
      {...rest}
      className={cn(
        styles.ProductRoot,
        className,
        StylesMap[direction]
      )}
    >
      {children}
    </div>
  );
});
