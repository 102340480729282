// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Qbhq8Hv{position:absolute;display:inline-flex;align-items:center;box-sizing:border-box;height:24px;margin:8px 0 0 8px;padding:0 8px 2px;font-size:16px;color:var(--color-white);border-radius:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "Qbhq8Hv"
};
export default ___CSS_LOADER_EXPORT___;
