import React, { type FC } from 'react';
import cn from 'classnames';

import {
  Typography,
  TypographyFontProps,
  TypographySizingProps,
  TypographyTagProps
} from '../../Typography';

import { RatingCounterValueProps } from '.';

import styles from './RatingCounterValue.module.scss';

export const RatingCounterValue: FC<
  RatingCounterValueProps
> = ({ value }) => {
  return (
    <Typography
      tag={TypographyTagProps.span}
      fontFamily={TypographyFontProps.display}
      size={TypographySizingProps._16}
      className={cn(styles.Root, {
        [styles.IsPositive]: value > 0,
        [styles.IsNegative]: value < 0
      })}
    >
      {value}
    </Typography>
  );
};
