import React, { FC } from 'react';
import styles from './Checkbox-Box.module.scss';

export interface ICheckboxBoxProps {
  /**
   * Состояние Checked/Unchecked чекбокса
   */
  state: boolean;
  'aria-disabled': boolean | 'false' | 'true';
}

export const CheckboxBox: FC<ICheckboxBoxProps> = ({
  state,
  'aria-disabled': ariaDisabled
}) => (
  <span
    className={styles.Root}
    role="checkbox"
    aria-checked={state}
    aria-disabled={ariaDisabled}
  >
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      aria-hidden="true"
      focusable="false"
      className={styles.BoxCheckmarkContainer}
    >
      <path
        className={styles.Checkmark}
        d="M1 6L5 10L13 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  </span>
);
