import React, {
  FC,
  LegacyRef,
  useEffect,
  useRef
} from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';

import { SelectGroup } from './SelectGroup';

import { useSelect } from '../../hooks/useSelect';
import { useClickOutside } from '../../hooks/useClickOutside';

import { SelectProps } from '.';

import styles from './Select.module.scss';

export const Select: FC<SelectProps> = ({
  options,
  value,
  placeholder,
  isError,
  isDisabled,
  errorText,
  isExpanded,
  className,
  style
}) => {
  const selectWrapperRef = useRef<HTMLDivElement>(null);
  const selectRef =
    useRef<LegacyRef<HTMLSelectElement>>(null);
  const idRef = useRef(uuid());
  const {
    currentValue,
    isExpanded: _isExpanded,
    collapseHandler,
    toggleHandler,
    changeHandler,
    optionKeyDownHandler
  } = useSelect({
    value,
    isOpened: isExpanded
  });
  const isClickedOutside = useClickOutside(
    selectWrapperRef
  );

  useEffect(() => {
    if (isClickedOutside) {
      collapseHandler();
    }
  }, [isClickedOutside, collapseHandler]);

  return (
    <div
      className={cn(styles.Select, className)}
      ref={selectWrapperRef}
      style={style}
    >
      <SelectGroup
        options={options}
        value={currentValue}
        placeholder={placeholder}
        isError={isError}
        isDisabled={isDisabled}
        errorText={errorText}
        isExpanded={_isExpanded}
        selectRef={selectRef}
        idRef={idRef}
        onExpandHandler={toggleHandler}
        onChangeHandler={changeHandler}
        onKeyDownHandler={optionKeyDownHandler}
      />
    </div>
  );
};
