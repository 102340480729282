// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._4Uxs-Ec{display:inline-flex;justify-content:space-between;align-items:center;animation:ac0dsJa .8s ease forwards}.\\-9jArzB{pointer-events:none;opacity:.4}@keyframes ac0dsJa{0%{opacity:0}70%{opacity:0}100%{opacity:1}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "_4Uxs-Ec",
	"show": "ac0dsJa",
	"Disabled": "-9jArzB"
};
export default ___CSS_LOADER_EXPORT___;
