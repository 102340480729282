import React, { FC } from 'react';

import { InputDescriptionProps } from './types';

import styles from './InputDescription.module.scss';

export const InputDescription: FC<
  InputDescriptionProps
> = ({ description }) => {
  return (
    <div className={styles.InputDescription}>
      {description}
    </div>
  );
};
