import { useState, useEffect } from 'react';
import { ViewSizeController } from './ViewSizeController';

const viewSizeControllerInstance =
  ViewSizeController.instance;

/**
 * Хук для получения текущего состояние браузера по средствам подписки на контроллер состояния, который в свою очередь
 * реализует отслеживание resize на объекте window, записывает текщее состояние и передаёт его в подписчиков.
 *
 * @returns - Возвращает текущее состояние контроллера ViewSizeController
 */
export const useViewSize = () => {
  const [viewSizeData, setViewSizeData] = useState(
    viewSizeControllerInstance.viewSizeData
  );

  useEffect(() => {
    viewSizeControllerInstance.subscribe(setViewSizeData);

    return () => {
      viewSizeControllerInstance.unsubscribe(
        setViewSizeData
      );
    };
  }, []);

  return viewSizeData;
};
