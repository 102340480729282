// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".lydrEsh{display:flex;justify-content:space-between;align-items:center;width:100%;max-width:116px;height:40px}.TClV\\+Rr{user-select:none;pointer-events:none;touch-action:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "lydrEsh",
	"IsDisabled": "TClV+Rr"
};
export default ___CSS_LOADER_EXPORT___;
