import React, { type FC } from 'react';
import cn from 'classnames';

import { RatingCounterButtonProps } from '.';

import styles from './RatingCounterButton.module.scss';

export const RatingCounterButton: FC<
  RatingCounterButtonProps
> = ({
  isActive = false,
  className,
  children,
  onClick
}) => {
  return (
    <button
      className={cn(
        styles.Root,
        {
          [styles.IsActive]: isActive
        },
        className
      )}
      type="button"
      onClick={onClick}
    >
      {children}
    </button>
  );
};
