// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EzV6rSd{overflow:auto;width:100%;max-height:192px;background-color:var(--color-white)}.EzV6rSd::-webkit-scrollbar{width:8px;background:var(--color-white)}.EzV6rSd::-webkit-scrollbar-track{width:8px;background-color:var(--color-graphite-sber-4)}.EzV6rSd::-webkit-scrollbar-thumb{width:8px;border:2px solid var(--color-graphite-sber-4);border-radius:8px;background:var(--color-graphite-sber-3)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectList": "EzV6rSd"
};
export default ___CSS_LOADER_EXPORT___;
