import React, { FC, HTMLAttributes } from 'react';
import { Icons } from '../../Icon';
import { ProductInformer } from './ProductInformer';

export const ProductInformerExpiration: FC<
  HTMLAttributes<HTMLDivElement>
> = (rest) => {
  return (
    <ProductInformer
      iconName={Icons.timeRound}
      iconColor="#FF1F78"
      color="#FF1F78"
      {...rest}
    >
      Годен менее 3 месяцев
    </ProductInformer>
  );
};
