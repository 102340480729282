import React, { type FC } from 'react';
import cn from 'classnames';

import { PaginationElementProps } from '.';
import { PaginationPagesProps } from '../';

import styles from './PaginationElement.module.scss';

export const PaginationElement: FC<
  PaginationElementProps
> = ({
  type,
  page,
  selected,
  disabled,
  ariaLabel,
  className,
  onClick
}) => {
  return (
    <>
      {type === PaginationPagesProps.startellipsis ||
      type === PaginationPagesProps.endellipsis ? (
        <div
          className={cn(
            styles.PaginationElement,
            {
              [styles.PaginationElementIsDisabled]: disabled
            },
            className
          )}
          aria-label="..."
        >
          ...
        </div>
      ) : (
        <button
          className={cn(
            styles.PaginationElement,
            {
              [styles.PaginationElementIsActive]: selected,
              [styles.PaginationElementIsDisabled]: disabled
            },
            className
          )}
          type="button"
          onClick={(e) => onClick(e, page)}
          aria-label={ariaLabel}
        >
          {page}
        </button>
      )}
    </>
  );
};
