import { BreakPointsKeys } from '../types';
import { desktopViewBreakPoints } from '../const';

/**
 * хелпер для проверки находится ли текущее ширина браузера внутри десктопного диапазона брейкпоинтов
 * @param currentBreakPoint - брейкпоинт для проверки нахождения в диапозоне `desktopViewBreakPoints`
 *
 * @returns {boolean} -  `true`, если брейкпоинт нахождения в диапозоне `desktopViewBreakPoints`, иначе `false`.
 */
export const checkIsDesktopBreakPoint = (
  currentBreakPoint: BreakPointsKeys
) => desktopViewBreakPoints.includes(currentBreakPoint);
