import { DispatcherSubscriber } from '../types';

/**
 * Создает простую систему обмена сообщениями (шину событий),
 * которая позволяет подписываться, отписываться и отправлять сообщения между различными частями приложения.
 * Позволяет управлять подписками на события или сообщения и вызывать функции обратного вызова
 * при возникновении этих событий или сообщений.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export class Dispatcher<A extends any[]> {
  private subscribers: DispatcherSubscriber<A>[] = [];

  /**
   * Удаляет подписчика из списка подписчиков.
   *
   * @param {DispatcherSubscriber<A>} subscriber - Функция обратного вызова подписчика для удаления.
   * @returns {boolean} - Возвращает `true`, если подписчик был успешно удален, иначе `false`.
   */
  public unsubscribe = (
    subscriber: DispatcherSubscriber<A>
  ): boolean => {
    const storeIndex = this.subscribers.indexOf(subscriber);
    const isUnsubsribe = storeIndex !== -1;

    if (isUnsubsribe) {
      this.subscribers.splice(storeIndex, 1);
    }

    return isUnsubsribe;
  };

  /**
   * Добавляет нового подписчика в список подписчиков.
   *
   * @param {DispatcherSubscriber<A>} subscriber - Функция обратного вызова подписчика для добавления.
   * @returns {Function} - Возвращает функцию, которую можно вызвать для отмены подписки.
   */
  public subscribe = (
    subscriber: DispatcherSubscriber<A>
  ): (() => void) => {
    this.subscribers.push(subscriber);
    return () => this.unsubscribe(subscriber);
  };

  /**
   * Отправляет сообщение всем подписчикам.
   *
   * @param {A} data - Данные для передачи подписчикам.
   * @returns {Array} - Возвращает массив результатов вызова функций обратного вызова подписчиков.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public dispatch = (...data: A): any[] => {
    return this.subscribers.map(
      (subscriber: DispatcherSubscriber<A>) => {
        try {
          return subscriber(...data);
        } catch (error) {
          console.error(error);

          return error;
        }
      }
    );
  };
}
