import { useState, useEffect } from 'react';

import { UseClickOutsideprops } from './types';

export const useClickOutside = (
  ref: UseClickOutsideprops
) => {
  const [isClickedOutside, setIsClickedOutside] =
    useState<boolean>(false);

  useEffect(() => {
    const clickOutsideHandler = (event: Event) => {
      if (
        ref?.current &&
        !ref?.current.contains(event.target as HTMLElement)
      ) {
        setIsClickedOutside(true);
      } else {
        setIsClickedOutside(false);
      }
    };

    document.addEventListener('click', clickOutsideHandler);

    return () => {
      document.removeEventListener(
        'click',
        clickOutsideHandler
      );
    };
  }, [ref]);

  return isClickedOutside;
};
