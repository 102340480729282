import { CSSProperties } from 'react';

export interface RatingCounterProps {
  count?: number;
  isDisabled?: boolean;
  isClicked?: RatingCounterClickedProps;
  className?: string;
  style?: CSSProperties;
  increaseHandler?: (arg: number) => void;
  decreaseHandler?: (arg: number) => void;
}

export enum RatingCounterActiveButtonProps {
  false = 'false',
  plus = 'plus',
  minus = 'minus'
}

export enum RatingCounterClickedProps {
  plus = 'plus',
  minus = 'minus'
}
