import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import cn from 'classnames';

import { usePreventScroll } from '../../hooks/usePreventScroll';
import { Portal } from '../Portal';
import { Button, ButtonThemes } from '../Button';
import { Icon, Icons } from '../Icon';
import { LayerManager } from '../LayerManager';
import { type IModalProps, TModalAlignment } from './types';
import { SizingProps } from '../../types/SizingProps';

import styles from './Modal.module.scss';

/**
 * Используется для создания всплывающих модальных окон.
 */
export const Modal: FC<IModalProps> = ({
  children,
  header,
  footer,
  className,
  classNames,
  classNameContainer,
  contentVerticalAlign: align = TModalAlignment.middle,
  size = SizingProps.s,
  hasCloseIcon = true,
  closeInsideContent = false,
  hasAnimation = true,
  innerRef,
  controlRef,
  keepMounted,
  onClose,
  preventBodyScroll = true,
  scope,
  visible,
  zIndex,
  ...props
}) => {
  const [animation, setAnimation] = useState(false);
  const [isShow, setShow] = useState(visible || false);
  const isVisible = useMemo(
    () => (hasAnimation ? animation : isShow),
    [hasAnimation, animation, isShow]
  );
  const contentRef = useRef<HTMLDivElement>(null);
  const onAnimationEnd = useCallback(() => {
    if (!animation) {
      setShow(false);
      onClose(null, 'click');
    }
  }, [onClose, animation]);
  const handleClose = useCallback(() => {
    if (hasAnimation) {
      setAnimation(false);
    } else {
      setShow(false);
      onClose(null, 'click');
    }
  }, [hasAnimation, onClose]);
  const closeButton = useMemo(
    () => (
      <Button
        className={cn(styles.Close, classNames?.close)}
        onClick={handleClose}
        theme={ButtonThemes.tertiary}
        size={SizingProps.m}
        icon
      >
        <Icon name={Icons.close} />
      </Button>
    ),
    [classNames, handleClose]
  );

  usePreventScroll({
    enabled: preventBodyScroll && isShow
  });

  useEffect(() => {
    if (hasAnimation) {
      setAnimation(visible);
      if (visible) setShow(visible);
    } else {
      setShow(visible);
    }
  }, [visible, hasAnimation]);

  return (
    <Portal
      scope={scope}
      visible={visible}
      keepMounted={keepMounted || isShow}
    >
      <LayerManager
        visible={visible}
        onClose={handleClose}
        essentialRefs={[contentRef]}
      >
        <div
          {...props}
          ref={innerRef || controlRef}
          className={cn([
            styles.Root,
            {
              [styles.RootIsVisible]: isVisible,
              [styles.RootHasAnimation]: hasAnimation,
              [styles.RootHasCloseIcon]: hasCloseIcon,
              [styles.RootCloseInside]: closeInsideContent,
              [styles.RootHasBody]: !!children,
              [styles.RootHasHeader]: !!header,
              [styles.RootHasFooter]: !!footer
            },
            className
          ])}
          style={{ zIndex }}
          onAnimationEnd={
            hasAnimation ? onAnimationEnd : undefined
          }
        >
          <div
            className={cn([
              styles.Overlay,
              classNames?.overlay
            ])}
          />
          <div
            className={cn([
              styles.Wrapper,
              classNames?.wrapper
            ])}
          >
            <div
              className={cn([
                styles.Container,
                classNames?.container,
                styles[`ContainerAlign${align}`]
              ])}
            >
              <div
                ref={contentRef}
                className={cn([
                  styles.Content,
                  styles[`ContentSize${size}`],
                  classNames?.content,
                  classNameContainer
                ])}
              >
                {header && (
                  <div
                    className={cn([
                      styles.Header,
                      classNames?.header
                    ])}
                  >
                    {header}
                  </div>
                )}
                {hasCloseIcon &&
                  !closeInsideContent &&
                  closeButton}
                <div
                  className={cn([
                    styles.Body,
                    classNames?.body
                  ])}
                >
                  {hasCloseIcon &&
                    closeInsideContent &&
                    closeButton}
                  {children}
                </div>
                {footer && (
                  <div
                    className={cn([
                      styles.Footer,
                      classNames?.footer
                    ])}
                  >
                    {footer}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LayerManager>
    </Portal>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Modal.displayName = 'Modal';
}
