// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Bt3p2xk{position:absolute;bottom:0;left:0;padding:4px 12px 4px 0;border-radius:0 8px 8px 0;background-color:var(--color-white);transform:translateY(50%)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "Bt3p2xk"
};
export default ___CSS_LOADER_EXPORT___;
