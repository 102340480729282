import React, { FC } from 'react';
import cn from 'classnames';

import type { ISwitcher } from '../Switcher';

import styles from './Switcher-Control.module.scss';

export const SwitchControl: FC<ISwitcher> = ({
  onChange,
  disabled,
  checked = false,
  controlRef,
  id,
  name,
  ...props
}) => {
  return (
    <input
      type="checkbox"
      className={cn(styles.Control)}
      onChange={onChange}
      disabled={disabled}
      checked={checked}
      id={id}
      ref={controlRef}
      name={name}
      {...props}
    />
  );
};
