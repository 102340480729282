import React, { type FC } from 'react';
import cn from 'classnames';

import {
  Typography,
  TypographySizingProps
} from '../../Typography';

import { TextareaDescriptionProps } from '.';

import styles from './TextareaDescription.module.scss';

export const TextareaDescription: FC<
  TextareaDescriptionProps
> = ({ description }) => {
  return (
    <Typography
      className={cn(styles.TextareaDescription)}
      size={TypographySizingProps._14}
    >
      {description}
    </Typography>
  );
};
