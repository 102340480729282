// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Preloader.assets/preloader.gif";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".X1O4unP{position:relative;display:inline-block;box-sizing:border-box;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.NEGUiWf{position:absolute;top:50%;left:50%;will-change:transform;transform:translate(-50%, -50%)}._1cgmcX7{width:36px;height:36px}.GZ7KrKX{width:42px;height:42px}.eJyPhGU{width:58px;height:58px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "X1O4unP",
	"CenterPosition": "NEGUiWf",
	"RootSizeS": "_1cgmcX7",
	"RootSizeM": "GZ7KrKX",
	"RootSizeL": "eJyPhGU"
};
export default ___CSS_LOADER_EXPORT___;
