import type { ElementType } from 'react';
import type { ReactTag } from '../../types/tags';
import { SizingProps } from '../../types/SizingProps';

export enum ButtonThemes {
  primary = 'Primary',
  secondary = 'Secondary',
  tertiary = 'Tertiary',
  ghost = 'Ghost'
}

export type ButtonProps<
  HTMLElement,
  ReactElementAttributes
> = ReactTag<HTMLElement, ReactElementAttributes> & {
  uniqueClass?: string;
  tag?: ElementType;
  theme?: ButtonThemes;
  size?: SizingProps;
  disabled?: boolean;
  fullWidth?: boolean;
  icon?: boolean;
};
