import React, { FC, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';

import { InputGroup } from './InputGroup';

import { useInputText } from '../../hooks/useInputText';

import { SizingProps } from '../../types/SizingProps';

import { InputProps, InputTypesProps } from './types';

import styles from './Input.module.scss';

export const Input: FC<InputProps> = ({
  controlRef,
  innerRef,
  className,
  inputGroupClassName,
  placeholder,
  value,
  isError = false,
  errorText = 'Заполните поле',
  description,
  isDisabled = false,
  type = InputTypesProps.text,
  sizing = SizingProps.m,
  hasClearButton = true,
  isReadonly = false,
  addonAfter,
  style,
  onChangeHandler,
  onFocusHandler,
  onBlurHandler
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const idRef = useRef(uuid());
  const {
    hasFocus: isFocused,
    onFocus,
    onBlur
  } = useInputText({
    onFocus: onFocusHandler,
    onBlur: onBlurHandler
  });

  return (
    <div
      className={cn(styles.Input, className)}
      ref={innerRef}
      style={style}
    >
      <InputGroup
        placeholder={placeholder}
        value={value}
        isError={isError}
        errorText={errorText}
        description={description}
        isDisabled={isDisabled}
        isFocused={isFocused || value !== ''}
        type={type}
        sizing={sizing}
        hasClearButton={hasClearButton}
        controlRef={controlRef || inputRef}
        idRef={idRef}
        inputGroupClassName={inputGroupClassName}
        isReadonly={isReadonly}
        addonAfter={addonAfter}
        onChangeHandler={onChangeHandler}
        onFocusHandler={onFocus}
        onBlurHandler={onBlur}
      />
    </div>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Input.displayName = 'Input';
}
