// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+-rbW5E{display:flex;justify-content:center;align-items:center;padding:0;font-family:var(--control-font-family);font-size:14px;cursor:pointer;user-select:none;color:var(--color-graphite-sber-1);border:0;border-radius:8px;outline:0;background-color:var(--color-white);transition:all .2s linear;appearance:none}.\\+-rbW5E:hover{color:var(--color-violet)}div.\\+-rbW5E{pointer-events:none;touch-action:none}._9i6-HjC,.vPm7-gm{pointer-events:none;touch-action:none}._9i6-HjC{color:var(--color-violet);background-color:var(--color-graphite-sber-4)}.vPm7-gm{color:var(--color-graphite-sber-3)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PaginationElement": "+-rbW5E",
	"PaginationElementIsActive": "_9i6-HjC",
	"PaginationElementIsDisabled": "vPm7-gm"
};
export default ___CSS_LOADER_EXPORT___;
