import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';
import { Typography } from '../../Typography';

import styles from './ProductDeliveryInfo.module.scss';

export interface ProductDeliveryInfoOptions {
  delivery?: string;
  pickup?: string;
}

export interface ProductDeliveryInfoProps
  extends ProductDeliveryInfoOptions,
    HTMLAttributes<HTMLDivElement> {}

export const ProductDeliveryInfo: FC<
  ProductDeliveryInfoProps
> = ({ delivery, pickup, ...rest }) => {
  if (!delivery && !pickup) {
    return null;
  }

  return (
    <div className={cn(styles.Root)} {...rest}>
      {Boolean(delivery) && (
        <Typography className={styles.Text}>
          {delivery}
        </Typography>
      )}
      {Boolean(pickup) && (
        <Typography className={styles.Text}>
          {pickup}
        </Typography>
      )}
    </div>
  );
};
