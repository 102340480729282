// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._5Ausqd2{position:relative;display:inline-flex;align-items:center;box-sizing:border-box;width:fit-content;border-radius:4px}._5Ausqd2:focus-within{outline:4px solid var(--color-violet-1)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "_5Ausqd2"
};
export default ___CSS_LOADER_EXPORT___;
