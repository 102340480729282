import React, { type FC, Fragment } from 'react';
import cn from 'classnames';

import type { IRatingInputProps } from '../types';
import { defaultValues, noop, hash } from './consts';

import styles from './RatingInput.module.scss';

export const RatingInput: FC<IRatingInputProps> = ({
  values = defaultValues,
  onChange = noop,
  value,
  className,
  innerRef,
  controlRef,
  tag: Component = 'fieldset',
  ...props
}) => (
  <Component
    className={cn(styles.RatingInput, className)}
    ref={innerRef || controlRef}
    {...props}
  >
    {values.map((input) => {
      const id = input.value + hash;
      const checked =
        Math.min(value, defaultValues.length) ===
        input.value;

      return (
        <Fragment key={id}>
          <input
            className={cn(styles.Input)}
            checked={checked}
            type="radio"
            id={id}
            name={hash}
            value={input.value}
            onChange={onChange}
          />
          <label className={cn(styles.Label)} htmlFor={id}>
            {input.title}
          </label>
        </Fragment>
      );
    })}
  </Component>
);

if (process.env.NODE_ENV !== 'production') {
  RatingInput.displayName = 'RatingInput';
}
