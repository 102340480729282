import React, { ChangeEventHandler, FC, Ref } from 'react';
import cn from 'classnames';

import { SwitchControl } from './Control/Switcher-Control';
import { SwitchSlider } from './Slider/Switcher-Slider';

import styles from './Switcher.module.scss';

export enum SwitcherVariantProps {
  default = 'default',
  green = 'green'
}

export interface ISwitcher {
  /**
   * Событие для изменения состояния переключателя
   */
  onChange?: ChangeEventHandler<HTMLInputElement>;

  /**
   * Значение переключателя (выбран/не выбран)
   *
   * @default false
   */
  checked?: boolean;

  /**
   * Доступность для взаимодействия с переключателем
   */
  disabled?: boolean;

  /**
   * Обязательность поля
   */
  required?: boolean;

  /**
   * Уникальный идентификатор
   */
  id?: string;

  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Название переключателя
   */
  name?: string;

  /**
   * Ссылка на корневой DOM элемент компонента
   */
  innerRef?: Ref<HTMLSpanElement>;

  /**
   * Ссылка на DOM элемент нативного контрола
   */
  controlRef?: Ref<HTMLInputElement>;

  /**
   *  Цветовая тема
   * */
  variant?: SwitcherVariantProps;
}

export const Switcher: FC<ISwitcher> = ({
  onChange,
  checked,
  disabled,
  className,
  innerRef,
  controlRef,
  required,
  name,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  id,
  variant = SwitcherVariantProps.default,
  ...props
}) => {
  return (
    <span
      className={cn(
        styles.Root,
        { [styles.Disabled]: disabled },
        [className]
      )}
      ref={innerRef}
      {...props}
    >
      <SwitchControl
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        controlRef={controlRef}
        required={required}
        name={name}
      />
      <SwitchSlider state={checked} variant={variant} />
    </span>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Switcher.displayName = 'Switcher';
}
