import {
  FC,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState
} from 'react';
import { createPortal } from 'react-dom';

import { canUseDOM } from '../../helpers/canUseDOM';

export type IPortalProps = {
  /**
   * Флаг показа содержимого в документе
   */
  visible?: boolean;

  /**
   * Сохраняет содержимое в документе после закрытия
   *
   * @default true
   */
  keepMounted?: boolean;

  /**
   * Содержимое портала
   */
  children: ReactElement;

  /**
   * Ссылка на контейнер куда будет размещен портал.
   *
   * Если в качестве значение указано `withoutportal`,
   * то содержимое будет показано без использования портала
   *
   * @default RefObject<HTMLBodyElement>
   */
  scope?: RefObject<HTMLElement> | 'withoutportal';
};

export type PortalExtendableProps = Omit<
  IPortalProps,
  'children'
>;

const canUseDom = canUseDOM();
const defaultContainer = canUseDom ? document.body : null;

export const Portal: FC<IPortalProps> = (props) => {
  const {
    visible,
    keepMounted = true,
    children,
    scope
  } = props;
  const [container, setContainer] = useState(
    defaultContainer
  );
  const isPortalDisabled = scope === 'withoutportal';
  const isWasVisibleRef = useRef(false);

  useEffect(() => {
    if (typeof scope === 'object') {
      setContainer(scope.current);
    }
  }, [scope]);

  if (visible) {
    isWasVisibleRef.current = true;
  }

  const shouldClientRender =
    canUseDom &&
    (visible || (keepMounted && isWasVisibleRef.current));
  const shouldServerRender = !canUseDom && visible;

  if (!shouldClientRender && !shouldServerRender) {
    return null;
  }

  if (isPortalDisabled) {
    return children;
  }

  return container
    ? createPortal(children, container)
    : null;
};

if (process.env.NODE_ENV !== 'production') {
  Portal.displayName = 'Portal';
}
