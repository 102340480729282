// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".r-pb\\+3N{position:relative;z-index:3;display:flex;align-items:center;padding:2px}.NsTUGoy{pointer-events:none;opacity:.5;touch-action:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputAddon": "r-pb+3N",
	"InputAddonIsDisabled": "NsTUGoy"
};
export default ___CSS_LOADER_EXPORT___;
