import type { ReactTag } from '../../types/tags';

export enum LinkThemes {
  default = 'default',
  black = 'black',
  success = 'success',
  warning = 'warning',
  danger = 'danger'
}

export type LinkProps<HTMLElement, ReactElementAttributes> =
  ReactTag<HTMLElement, ReactElementAttributes> & {
    theme?: LinkThemes;
  };
