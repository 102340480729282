import React, { useMemo } from 'react';
import clsx from 'clsx';
import { type IPreloaderProps } from './types';
import { SizingProps } from '../../types/SizingProps';
import { preloaderSizesMap } from './consts';

import styles from './Preloader.module.scss';

export const Preloader = <Element,>({
  className,
  innerRef,
  style = {},
  size = SizingProps.m,
  position,
  tag = 'div',
  children,
  ...props
}: IPreloaderProps<Element>) => {
  const Component = tag;

  const classString = useMemo(
    () =>
      clsx(styles.Root, {
        [preloaderSizesMap[size]]: size,
        [styles[`${position}Position`]]: position,
        [className]: className
      }),
    [className, position, size]
  );

  return (
    <Component
      style={style}
      className={classString}
      ref={innerRef}
      {...props}
    >
      {children}
    </Component>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Preloader.displayName = 'Preloader';
}
