import React, { FC } from 'react';
import cn from 'classnames';

import {
  TypographyProps,
  TypographyFontProps,
  TypographyTagProps,
  TypographySizingProps,
  TypographyFontWeightProps
} from './types';

import styles from './Typography.module.scss';

export const Typography: FC<TypographyProps> = ({
  tag = TypographyTagProps.p,
  fontFamily = TypographyFontProps.text,
  size = TypographySizingProps._16,
  sizeTablet,
  sizeMobile,
  fontWeight = fontFamily === TypographyFontProps.display
    ? TypographyFontWeightProps.semiBold
    : TypographyFontWeightProps.regular,
  children,
  className,
  style,
  ...props
}) => {
  const Root = tag;

  return (
    <Root
      className={cn(
        styles.Root,
        styles[`FontSB${fontFamily}`],
        styles[`Size${size}`],
        styles[`Font${fontWeight}`],
        styles[`SizeTablet${sizeTablet}`],
        styles[`SizeMobile${sizeMobile}`],
        className
      )}
      style={style}
      {...props}
    >
      {children}
    </Root>
  );
};
