import React, {
  type ButtonHTMLAttributes,
  type FC,
  useMemo
} from 'react';
import clsx from 'clsx';
import { SizingProps } from '../../types/SizingProps';
import { type ButtonProps } from './types';
import { buttonSizesMap, ButtonThemesMap } from './consts';
import Styles from './Button.module.scss';

export const Button = <
  Element = HTMLButtonElement,
  ReactAttributes = ButtonHTMLAttributes<Element>
>({
  tag = 'button',
  theme,
  size = SizingProps.m,
  uniqueClass,
  className,
  children,
  fullWidth,
  icon,
  innerRef,
  ...props
}: ButtonProps<Element, ReactAttributes>) => {
  const Tag = tag as unknown as FC;

  const classNameString = useMemo(() => {
    if (uniqueClass) {
      return uniqueClass;
    }

    return clsx(Styles.BaseButton, Styles.Button, {
      [className]: className,
      [buttonSizesMap[size]]: size,
      [ButtonThemesMap[theme]]: theme,
      [Styles.buttonFullWidth]: fullWidth,
      [Styles.buttonIcon]: icon
    });
  }, [
    icon,
    fullWidth,
    uniqueClass,
    className,
    theme,
    size
  ]);

  return (
    <Tag
      ref={innerRef}
      className={classNameString}
      {...props}
    >
      {children}
    </Tag>
  );
};
