// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+iz86Il{position:absolute;top:0;left:0;overflow:hidden;width:100%;padding-top:48px;padding-right:2px;border:1px solid #9747ff;border-radius:8px;box-shadow:0 4px 20px 0 rgba(0,0,0,.1)}.E-FS4KK{border-color:var(--color-pink-sber)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectDropdown": "+iz86Il",
	"SelectDropdownIsError": "E-FS4KK"
};
export default ___CSS_LOADER_EXPORT___;
