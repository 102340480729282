import React, { FC } from 'react';

import { SelectNativeProps } from './types';

import styles from './SelectNative.module.scss';

export const SelectNative: FC<SelectNativeProps> = ({
  id,
  selectRef,
  options,
  currentValue,
  onChangeHandler
}) => {
  return (
    <select
      id={id}
      ref={selectRef.current}
      className={styles.SelectNative}
      value={currentValue}
      onChange={() => onChangeHandler(currentValue)}
    >
      {options.map(({ id, value }) => (
        <option key={id} value={value}>
          {value}
        </option>
      ))}
    </select>
  );
};
