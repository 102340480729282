/**
 * Выбирает подходящий вариант слова для числа с учетом падежей в русском языке.
 *
 * @template V - Тип вариантов.
 * @param {number} value - Число, для которого нужно подобрать слово.
 * @param {V[]} variants - Массив вариантов слова для разных чисел (для одного, для двух-четырех, для пяти и более).
 * @returns {V} - Возвращает подходящий вариант слова для числа.
 */
export function pluralizeWord<V>(
  value: number,
  variants: V[]
): V {
  const number = Math.abs(value) % 100;

  if (number > 10 && number < 20) {
    return variants[2];
  }

  const remainder = number % 10;

  if (remainder > 1 && remainder < 5) {
    return variants[1];
  }

  if (remainder === 1) {
    return variants[0];
  }

  return variants[2];
}
