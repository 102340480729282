import { CSSProperties, ChangeEvent } from 'react';
import { SizingProps } from '../../types/SizingProps';

export enum PaginationPagesProps {
  page = 'page',
  first = 'first',
  last = 'last',
  next = 'next',
  previous = 'previous',
  startellipsis = 'start-ellipsis',
  endellipsis = 'end-ellipsis'
}

export type ChangePageHandler = (
  event: ChangeEvent<unknown>,
  value: number
) => void;

export interface PaginationProps {
  page: number;
  /**
   * Количество всегда видимых страниц в начале и конце
   * @default 1
   */
  boundaryCount?: number;
  className?: string;
  count: number;
  size?: SizingProps;
  disabled?: boolean;
  hasArrows?: boolean;
  /**
   * Коллбэк для смены страниц
   *
   * @param {React.ChangeEvent<unknown>} event The event source of the callback.
   * @param {number} page The page selected.
   */
  onChange?: ChangePageHandler;
  /**
   * Количество рядом стоящих элементов с выбранной страницей
   * */
  siblingCount?: number;
  style?: CSSProperties;
}
