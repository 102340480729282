// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YBJGjvN{display:inline-flex;align-items:center;font-size:1rem}.RndPrZC{margin-left:.25em;font-family:var(--rating-font-family);font-size:.875rem;font-weight:600;color:var(--color-yellow)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"RatingNumber": "YBJGjvN",
	"Value": "RndPrZC"
};
export default ___CSS_LOADER_EXPORT___;
