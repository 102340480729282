import { useEffect, useCallback } from 'react';

import { UseTextareaDynamicHeightProps } from '.';

export const useTextareaDynamicHeight = ({
  textAreaRef,
  value
}: UseTextareaDynamicHeightProps) => {
  const updateTextareaHeight = useCallback(() => {
    if (textAreaRef) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
    }
  }, [textAreaRef]);

  useEffect(() => {
    updateTextareaHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateTextareaHeight();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [textAreaRef, value]);
};
