import { SizingProps } from '../../types/SizingProps';

import styles from './Pagination.module.scss';

interface PaginationSizingsProps {
  [index: string]: string;
}

export const paginationSizes = {
  [SizingProps.s]: styles.PaginationSizeS,
  [SizingProps.m]: styles.PaginationSizeM
} as PaginationSizingsProps;
