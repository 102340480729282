import { BreakPointsKeys } from '../types';
import { mobileViewBreakPoints } from '../const';

/**
 * хелпер для проверки находится ли текущее ширина браузера внутри мобильного диапазона брейкпоинтов
 * @param currentBreakPoint - брейкпоинт для проверки нахождения в диапозоне `mobileViewBreakPoints`
 *
 * @returns {boolean} -  `true`, если брейкпоинт нахождения в диапозоне `mobileViewBreakPoints`, иначе `false`.
 */
export const checkIsMobileBreakPoint = (
  currentBreakPoint: BreakPointsKeys
) => mobileViewBreakPoints.includes(currentBreakPoint);
