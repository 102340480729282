import React, { FC } from 'react';
import { ICheckbox } from '../Checkbox';
import styles from './Checkbox-Control.module.scss';

export const CheckboxControl: FC<ICheckbox> = ({
  onChange,
  name,
  disabled,
  checked = false,
  tabIndex = 0,
  autoFocus = false,
  controlRef,
  ...props
}) => {
  return (
    <input
      type="checkbox"
      className={styles.Root}
      onChange={onChange}
      name={name}
      disabled={disabled}
      defaultChecked={checked}
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      ref={controlRef}
      {...props}
    />
  );
};
