import React, { FC } from 'react';
import cn from 'classnames';

import { Input } from '../../Input';

import { Root } from '.';

import { SelectGroupProps } from './types';

import { InputTypesProps } from '../../Input/types';
import { SizingProps } from '../../../types/SizingProps';

import styles from './SelectGroup.module.scss';

export const SelectGroup: FC<SelectGroupProps> = ({
  options,
  value,
  placeholder,
  isError,
  isDisabled,
  errorText,
  isExpanded,
  idRef,
  selectRef,
  onExpandHandler,
  onChangeHandler,
  onKeyDownHandler
}) => {
  const { current: nativeSelectId } = idRef;

  return (
    <div className={styles.SelectGroup}>
      <Input
        inputGroupClassName={cn({
          [styles.SelectGroupInputGroupIsExpanded]:
            isExpanded,
          [styles.SelectGroupInputGroupIsDisabled]:
            isDisabled
        })}
        value={value}
        placeholder={placeholder}
        isError={isError}
        isDisabled={isDisabled}
        errorText={errorText}
        hasClearButton={false}
        type={InputTypesProps.text}
        sizing={SizingProps.m}
        isReadonly={true}
        onFocusHandler={onExpandHandler}
      />
      <Root.Trigger
        isExpanded={isExpanded}
        isDisabled={isDisabled}
        onExpandHandler={onExpandHandler}
      />
      {isExpanded && !isDisabled && (
        <Root.Dropdown isError={isError}>
          <Root.List
            options={options}
            currentValue={value}
            onChangeHandler={onChangeHandler}
            onExpandHandler={onExpandHandler}
            onKeyDownHandler={onKeyDownHandler}
          />
        </Root.Dropdown>
      )}
      <Root.Native
        id={nativeSelectId}
        selectRef={selectRef}
        currentValue={value}
        options={options}
        onChangeHandler={onChangeHandler}
      />
    </div>
  );
};
