import React, { type FC } from 'react';
import cn from 'classnames';
import SwiperCarouselContainer from './SwiperCarouselContainer';
import SwiperCarouselArrows from './SwiperCarouselArrows';
import type {
  ICarouselProps,
  ICarouselBreakpointsProps
} from './types';

import styles from './SwiperCarousel.module.scss';

const defaultBreakpoints = {
  '768': {
    slidesPerView: 4,
    spaceBetween: 24
  },
  '992': {
    slidesPerView: 5
  },
  '1200': {
    slidesPerView: 6
  }
} as ICarouselBreakpointsProps;

const SwiperCarousel: FC<ICarouselProps> = ({
  controlRef,
  innerRef,
  className,
  slides,
  slidesPerView = 'auto',
  slidesPerGroup = 1,
  navigation = true,
  autoplay = false,
  autoplayDelay = 3500,
  autoplayDisableOnInteraction = false,
  autoplayPauseOnMouseEnter = false,
  spaceBetween = 16,
  loop = false,
  isOverflowVisible = false,
  breakpoints = defaultBreakpoints,
  onInit,
  onChange,
  style = {},
  ...props
}) => {
  if (!slides.length) {
    return null;
  }
  if (
    slidesPerView !== 'auto' &&
    slidesPerGroup > slidesPerView
  ) {
    slidesPerGroup = slidesPerView;
  }
  return (
    <div
      className={cn(
        styles.Root,
        { [styles.RootNoOverflow]: isOverflowVisible },
        className
      )}
      style={style}
      ref={innerRef || controlRef}
    >
      <SwiperCarouselContainer
        slides={slides}
        slidesPerView={slidesPerView}
        slidesPerGroup={slidesPerGroup}
        autoplay={autoplay}
        autoplayDelay={autoplayDelay}
        autoplayDisableOnInteraction={
          autoplayDisableOnInteraction
        }
        autoplayPauseOnMouseEnter={
          autoplayPauseOnMouseEnter
        }
        spaceBetween={spaceBetween}
        loop={loop}
        breakpoints={breakpoints}
        onInit={onInit}
        onChange={onChange}
        {...props}
      />

      {navigation && <SwiperCarouselArrows />}
    </div>
  );
};

export default SwiperCarousel;

if (process.env.NODE_ENV !== 'production') {
  SwiperCarousel.displayName = 'SwiperCarousel';
}
