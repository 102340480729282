import type {
  CSSProperties,
  ElementType,
  RefObject,
  ReactNode
} from 'react';

import { SizingProps } from '../../types/SizingProps';

export enum PreloaderPositions {
  Center = 'Center'
}

export interface IPreloaderProps<Element = HTMLDivElement> {
  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Позиционирование
   */
  position?: PreloaderPositions;

  /**
   * Размер
   *
   * @default m
   */
  size?: SizingProps;

  /**
   * CSS-стили
   */
  style?: CSSProperties;

  /**
   * Ссылка на DOM элемент компонента
   */
  innerRef?: RefObject<Element>;

  /**
   * Тег элемента
   * @default div
   */
  tag?: ElementType;

  children?: ReactNode | undefined;
}
