import {
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from 'react';
import { Placement } from './types';

export const useTooltipPosition = (
  tooltipRef: MutableRefObject<HTMLDivElement>,
  triggerRef: MutableRefObject<HTMLDivElement>,
  placement: Placement,
  isVisible: boolean
): Placement => {
  const [position, setPosition] = useState(placement);

  const getDistanceToBorders = useCallback(() => {
    const rect = tooltipRef.current.getBoundingClientRect();
    const distanceToTop = rect.top;
    const distanceToRight = window.innerWidth - rect.right;
    const distanceToBottom =
      window.innerHeight - rect.bottom;
    const distanceToLeft = rect.left;

    return {
      top: distanceToTop,
      right: distanceToRight,
      bottom: distanceToBottom,
      left: distanceToLeft
    };
  }, [tooltipRef]);

  const handleTooltipPosition = useCallback(() => {
    const { top, right, bottom, left } =
      getDistanceToBorders();

    const isBottom = bottom > 0;
    const isTop = top > 0;
    const isRight = right > 0;
    const isLeft = left > 0;

    const yPosition = isTop ? 'top' : 'bottom';
    const xPosition = isRight ? 'right' : 'left';
    const isBottomByDefault = placement.includes('bottom');
    const isTopByDefault = placement.includes('top');
    const isRightByDefault = placement.includes('right');
    const isLeftByDefault = placement.includes('left');

    if (!isTop && !isBottom) {
      return setPosition(xPosition);
    }
    if (!isRight && !isLeft) {
      return setPosition(yPosition);
    }

    if (isTop && isBottom && isRight && isLeft) {
      return placement;
    }

    if (isTopByDefault) {
      if (!isTop && isBottom && !isRight)
        return setPosition('bottom-end');
      if (!isTop && isBottom && !isLeft)
        return setPosition('bottom-start');
      if (!isTop && isBottom) return setPosition('bottom');

      if (isTop && !isRight)
        return setPosition('bottom-end');
      if (isTop && !isLeft)
        return setPosition('bottom-start');
      if (isTop) return setPosition('top');
    }
    if (isBottomByDefault) {
      if (isTop && !isBottom && !isRight)
        return setPosition('top-end');
      if (isTop && !isBottom && !isLeft)
        return setPosition('top-start');
      if (isTop && !isBottom) return setPosition('top');

      if (isBottom && !isRight)
        return setPosition('bottom-end');
      if (isBottom && !isLeft)
        return setPosition('bottom-start');
      if (isBottom) return setPosition('bottom');
    }
    if (isRightByDefault) {
      if (isTop && !isBottom)
        return setPosition('top-start');
      if (!isTop && isBottom)
        return setPosition('bottom-start');

      if (isTop && !isRight) return setPosition('top-end');
      if (isBottom && !isRight)
        return setPosition('bottom-end');
    }
    if (isLeftByDefault) {
      if (isTop && !isBottom) return setPosition('top-end');
      if (!isTop && isBottom)
        return setPosition('bottom-end');

      if (isTop && !isLeft) return setPosition('top-end');
      if (isBottom && !isLeft)
        return setPosition('bottom-end');
    }

    return placement;
  }, [getDistanceToBorders, placement]);

  const updateTooltipPosition = useCallback(() => {
    if (tooltipRef.current && triggerRef.current) {
      const triggerRect =
        triggerRef.current.getBoundingClientRect();
      const tooltipRect =
        tooltipRef.current.getBoundingClientRect();
      const MARGIN = 10;
      let top = 0;
      let left = 0;

      switch (position) {
        case 'top-start':
          top =
            triggerRect.top +
            window.scrollY -
            tooltipRect.height -
            MARGIN;
          left = triggerRect.left + window.scrollX;
          break;
        case 'top':
          top =
            triggerRect.top +
            window.scrollY -
            tooltipRect.height -
            MARGIN;
          left =
            triggerRect.left +
            window.scrollX +
            (triggerRect.width - tooltipRect.width) / 2;
          break;
        case 'top-end':
          top =
            triggerRect.top +
            window.scrollY -
            tooltipRect.height -
            MARGIN;
          left =
            triggerRect.left +
            window.scrollX +
            triggerRect.width -
            tooltipRect.width;
          break;
        case 'bottom-start':
          top =
            triggerRect.top +
            window.scrollY +
            triggerRect.height +
            MARGIN;
          left = triggerRect.left + window.scrollX;
          break;
        case 'bottom':
          top =
            triggerRect.top +
            window.scrollY +
            triggerRect.height +
            MARGIN;
          left =
            triggerRect.left +
            window.scrollX +
            (triggerRect.width - tooltipRect.width) / 2;
          break;
        case 'bottom-end':
          top =
            triggerRect.top +
            window.scrollY +
            triggerRect.height +
            MARGIN;
          left =
            triggerRect.left +
            window.scrollX +
            triggerRect.width -
            tooltipRect.width;
          break;
        case 'left-start':
          top = triggerRect.top + window.scrollY;
          left =
            triggerRect.left +
            window.scrollX -
            tooltipRect.width -
            MARGIN;
          break;
        case 'left':
          top =
            triggerRect.top +
            window.scrollY +
            (triggerRect.height - tooltipRect.height) / 2;
          left =
            triggerRect.left +
            window.scrollX -
            tooltipRect.width -
            MARGIN;
          break;
        case 'left-end':
          top =
            triggerRect.top +
            window.scrollY +
            triggerRect.height -
            tooltipRect.height;
          left =
            triggerRect.left +
            window.scrollX -
            tooltipRect.width -
            MARGIN;
          break;
        case 'right-start':
          top = triggerRect.top + window.scrollY;
          left =
            triggerRect.left +
            window.scrollX +
            triggerRect.width +
            MARGIN;
          break;
        case 'right':
          top =
            triggerRect.top +
            window.scrollY +
            (triggerRect.height - tooltipRect.height) / 2;
          left =
            triggerRect.left +
            window.scrollX +
            triggerRect.width +
            MARGIN;
          break;
        case 'right-end':
          top =
            triggerRect.top +
            window.scrollY +
            triggerRect.height -
            tooltipRect.height;
          left =
            triggerRect.left +
            window.scrollX +
            triggerRect.width +
            MARGIN;
          break;
        default:
          top =
            triggerRect.top +
            window.scrollY -
            tooltipRect.height -
            MARGIN;
          left =
            triggerRect.left +
            window.scrollX +
            (triggerRect.width - tooltipRect.width) / 2;
          break;
      }

      tooltipRef.current.style.top = `${top}px`;
      tooltipRef.current.style.left = `${left}px`;
      tooltipRef.current.style.opacity = '1';
    }
  }, [tooltipRef, triggerRef, position]);

  useEffect(() => {
    const tooltip = triggerRef.current;
    if (tooltip) {
      tooltip.addEventListener(
        'mouseenter',
        handleTooltipPosition
      );
      tooltip.addEventListener('mouseleave', () =>
        setPosition(placement)
      );
    }

    return () => {
      tooltip.removeEventListener(
        'mouseenter',
        handleTooltipPosition
      );
      tooltip.removeEventListener('mouseleave', () =>
        setPosition(placement)
      );
    };
  }, [handleTooltipPosition, placement, triggerRef]);

  useLayoutEffect(() => {
    const trigger = triggerRef.current;
    updateTooltipPosition();

    trigger.addEventListener(
      'mouseenter',
      updateTooltipPosition
    );

    return () => {
      trigger.removeEventListener(
        'mouseenter',
        updateTooltipPosition
      );
    };
  }, [isVisible, triggerRef, updateTooltipPosition]);

  return position;
};

// function replaceFirstWordWithOpposite(str: string) {
//   const opposites = {
//       top: 'bottom',
//       bottom: 'top',
//       left: 'right',
//       right: 'left',
//   };

//   const words = str.split('-');
//   const firstWord = words[0];

//   // eslint-disable-next-line @typescript-eslint/ban-ts-comment
//   // @ts-ignore
//   words[0] = opposites[firstWord];

//   return words.join('-');
// }
