import {
  EffectCallback,
  DependencyList,
  useEffect,
  useLayoutEffect
} from 'react';

import { canUseDOM } from '../../helpers/canUseDOM';

export function useIsomorphicLayoutEffect(
  fn: EffectCallback,
  deps?: DependencyList
): void {
  /* eslint-disable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
  return canUseDOM()
    ? useLayoutEffect(fn, deps)
    : useEffect(fn, deps);
  /* eslint-enable react-hooks/rules-of-hooks, react-hooks/exhaustive-deps */
}
