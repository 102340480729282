// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._1P8VAN-{width:100%}.Y8j101d{position:relative;display:flex;align-items:center;width:100%;border:1px solid var(--color-graphite-sber-3);border-radius:8px;background-color:#fff;transition:border-color .25s linear}.xowt2JF{border-color:var(--color-pink-sber)}.xowt2JF.Y5mZitE{border-color:var(--color-pink-sber)}.Y5mZitE{border-color:var(--color-violet)}.Hwq51or{pointer-events:none;background-color:var(--color-graphite-sber-3);touch-action:none}.Hwq51or.xowt2JF,.Hwq51or.Y5mZitE{border-color:var(--color-graphite-sber-3)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputGroup": "_1P8VAN-",
	"InputGroupBody": "Y8j101d",
	"InputGroupBodyIsError": "xowt2JF",
	"InputGroupBodyIsFocused": "Y5mZitE",
	"InputGroupBodyIsDisabled": "Hwq51or"
};
export default ___CSS_LOADER_EXPORT___;
