// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".kj2JFA-{position:relative;width:100%}.zvE26gX{border-bottom-color:var(--color-graphite-sber-3);border-radius:8px 8px 0 0}.h33enVK{border-radius:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectGroup": "kj2JFA-",
	"SelectGroupInputGroupIsExpanded": "zvE26gX",
	"SelectGroupInputGroupIsDisabled": "h33enVK"
};
export default ___CSS_LOADER_EXPORT___;
