// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".S6raS2d{position:absolute;z-index:1;top:50%;left:0;display:block;overflow:hidden;align-items:center;width:100%;height:24px;font-size:16px;font-weight:normal;line-height:24px;user-select:none;white-space:nowrap;text-overflow:ellipsis;pointer-events:none;color:var(--color-graphite-sber-2);transition:all .25s linear;transform:translateY(-50%);padding-inline:16px;touch-action:none;will-change:transform}.YDB0BSB{top:12px;height:16px;font-size:12px;line-height:16px;color:var(--color-violet)}.YDB0BSB.c\\+6xZ3m{color:var(--color-graphite-sber-2)}.c\\+6xZ3m{color:var(--color-graphite-sber-2)}.la72mZZ{color:var(--color-pink-sber)}.la72mZZ.c\\+6xZ3m{color:var(--color-graphite-sber-2)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputPlaceholder": "S6raS2d",
	"InputPlaceholderIsFocused": "YDB0BSB",
	"InputPlaceholderIsDisabled": "c+6xZ3m",
	"InputPlaceholderIsError": "la72mZZ"
};
export default ___CSS_LOADER_EXPORT___;
