import { BreakPointsKeys, breakPointsMap } from './types';

/**
 * Карта брейкпоинтов
 */
export const viewBreakPoints: breakPointsMap = {
  [BreakPointsKeys.xxs]: 375,
  [BreakPointsKeys.xs]: 576,
  [BreakPointsKeys.sm]: 768,
  [BreakPointsKeys.md]: 992,
  [BreakPointsKeys.lg]: 1200,
  [BreakPointsKeys.xl]: 1280
};

/**
 * список брейкпоинтов
 */
export const viewBreakPointsKeys = Object.keys(
  viewBreakPoints
) as BreakPointsKeys[];

/**
 * Брейкпоинты мобильного интервала
 */
export const mobileViewBreakPoints = [
  BreakPointsKeys.xxs,
  BreakPointsKeys.xs,
  BreakPointsKeys.sm
];

/**
 * Брейкпоинты десктопного интервала
 */
export const desktopViewBreakPoints = [
  BreakPointsKeys.md,
  BreakPointsKeys.lg,
  BreakPointsKeys.xl
];
