const priceFormatOptions = {
  style: 'currency',
  currency: 'RUB',
  maximumSignificantDigits: 1
};

export const formatPrice = (price: number): string =>
  new Intl.NumberFormat('ru-RU', priceFormatOptions).format(
    price
  );
