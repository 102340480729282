import { SizingProps } from '../../types/SizingProps';

export interface IconSizesMapProps {
  [index: string]: number;
}

export const IconSizesMap = {
  [SizingProps.l]: 32,
  [SizingProps.m]: 24,
  [SizingProps.s]: 16
} as IconSizesMapProps;
