import React, { FC, HTMLAttributes } from 'react';
import cn from 'classnames';
import {
  Typography,
  TypographyProps,
  TypographyTagProps
} from '../../Typography';
import { Link } from '../../Link';

import styles from './ProductInfo.module.scss';

export interface ProductInfoProps
  extends TypographyProps,
    HTMLAttributes<HTMLHeadingElement> {
  label: string;
  name: string;
  url?: string;
}

export const ProductInfo: FC<ProductInfoProps> = ({
  className,
  tag = TypographyTagProps.p,
  label,
  name,
  url,
  ...rest
}) => {
  return (
    <Typography
      tag={tag}
      {...rest}
      className={cn(styles.Root, className)}
    >
      <Typography
        tag={TypographyTagProps.span}
        className={styles.Label}
      >
        {label}:{' '}
      </Typography>
      {url ? (
        <Link className={styles.Link} href={url}>
          {name}
        </Link>
      ) : (
        name
      )}
    </Typography>
  );
};
