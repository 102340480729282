import React, { type FC } from 'react';
import cn from 'classnames';

import { RatingStar } from './Star/RatingStar';
import { type IRatingProps } from './types';
import { SizingProps } from '../../types/SizingProps';

import styles from './Rating.module.scss';

export const Rating: FC<IRatingProps> = ({
  max = 5,
  filled = true,
  value,
  size = SizingProps.m,
  className,
  style,
  innerRef,
  controlRef,
  tag: Component = 'div',
  ...props
}) => {
  value = Math.min(value, max);
  const stars: number[] = [];
  const preparedValue: number = filled
    ? Math.round(value)
    : value;

  for (let star = 1; star <= max; star++) {
    const fullness: number =
      preparedValue >= star
        ? 1
        : Math.max(preparedValue - star + 1, 0);
    stars.push(fullness);
  }

  return (
    <Component
      className={cn(
        styles.Rating,
        styles[`RatingSize${size}`],
        className
      )}
      style={style}
      ref={innerRef || controlRef}
      role="img"
      aria-label={`Рейтинг ${value} из ${max}`}
      {...props}
    >
      {stars.map((fullness, key) => (
        <RatingStar key={key} fullness={fullness} />
      ))}
    </Component>
  );
};

if (process.env.NODE_ENV !== 'production') {
  Rating.displayName = 'Rating';
}
