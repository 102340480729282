import React, { FC } from 'react';
import cn from 'classnames';

import { Root } from '.';

import { InputGroupProps } from './types';

import styles from './InputGroup.module.scss';

export const InputGroup: FC<InputGroupProps> = ({
  placeholder,
  value,
  isError,
  errorText,
  description,
  type,
  sizing,
  isDisabled,
  isFocused,
  hasClearButton,
  controlRef,
  idRef,
  inputGroupClassName,
  isReadonly,
  addonAfter,
  onChangeHandler,
  onFocusHandler,
  onBlurHandler
}) => {
  return (
    <div className={styles.inputGroup}>
      <div
        className={cn(
          styles.InputGroupBody,
          inputGroupClassName,
          {
            [styles.InputGroupBodyIsError]: isError,
            [styles.InputGroupBodyIsDisabled]: isDisabled,
            [styles.InputGroupBodyIsFocused]: isFocused
          }
        )}
      >
        <Root.Field
          controlRef={controlRef}
          id={idRef.current}
          value={value}
          sizing={sizing}
          type={type}
          isDisabled={isDisabled}
          isError={isError}
          isFocused={isFocused}
          isReadonly={isReadonly}
          onChangeHandler={onChangeHandler}
          onFocus={onFocusHandler}
          onBlur={onBlurHandler}
        />
        {hasClearButton && value !== '' && (
          <Root.Clear
            isDisabled={isDisabled}
            controlRef={controlRef}
          />
        )}
        {addonAfter && (
          <Root.Addon isDisabled={isDisabled}>
            {addonAfter}
          </Root.Addon>
        )}
        {placeholder && (!isError || errorText === '') && (
          <Root.Placeholder
            placeholder={placeholder}
            isDisabled={isDisabled}
            isError={isError}
            isFocused={isFocused}
            labelId={idRef.current}
          />
        )}
        {isError && errorText !== '' && (
          <Root.Error
            error={errorText}
            isDisabled={isDisabled}
            isFocused={isFocused}
          />
        )}
      </div>
      {description && (
        <Root.Description description={description} />
      )}
    </div>
  );
};
