// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\-5xI9Q9{box-sizing:border-box;width:100%}.\\-5xI9Q9 *{box-sizing:border-box}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "-5xI9Q9"
};
export default ___CSS_LOADER_EXPORT___;
