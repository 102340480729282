import React, { type FC } from 'react';
import cn from 'classnames';

import { TextareaPlaceholderProps } from '.';

import styles from './TextareaPlaceholder.module.scss';

export const TextareaPlaceholder: FC<
  TextareaPlaceholderProps
> = ({
  placeholder,
  isDisabled,
  isFocused,
  isError,
  labelId
}) => {
  return (
    <label
      htmlFor={labelId}
      className={cn(styles.TextareaPlaceholder, {
        [styles.TextareaPlaceholderIsDisabled]: isDisabled,
        [styles.TextareaPlaceholderIsFocused]: isFocused,
        [styles.TextareaPlaceholderIsError]: isError
      })}
    >
      {placeholder}
    </label>
  );
};
