import React, { type FC } from 'react';
import cn from 'classnames';

import { SkeletonVerticalCard as VerticalCard } from './VerticalCard/Skeleton-VerticalCard';
import { SkeletonHorizontalCard as HorizontalCard } from './HorizontalCard/Skeleton-HorizontalCard';

import {
  SkeletonTypes,
  type ISkeletonProps
} from './types';
import styles from './Skeleton.module.scss';

export const Skeleton: FC<ISkeletonProps> = ({
  width,
  height,
  borderRadius,
  className,
  type,
  style = {},
  animated = true,
  controlRef,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  innerRef,
  ...props
}) => {
  switch (type) {
    case SkeletonTypes.verticalCard:
      return <VerticalCard />;
    case SkeletonTypes.horizontalCard:
      return <HorizontalCard />;
    default:
      return (
        <div
          className={cn(
            styles.Root,
            { [styles.Animated]: animated },
            className
          )}
          style={{ width, height, borderRadius, ...style }}
          ref={controlRef}
          {...props}
        />
      );
  }
};

if (process.env.NODE_ENV !== 'production') {
  Skeleton.displayName = 'Skeleton';
}
