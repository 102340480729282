import React, { type FC } from 'react';
import cn from 'classnames';

import { TextareaFieldProps } from '.';

import styles from './TextareaField.module.scss';

export const TextareaField: FC<TextareaFieldProps> = ({
  controlRef,
  id,
  value,
  height = 84,
  isDisabled,
  onChangeHandler,
  onFocusHandler,
  onBlurHandler
}) => {
  return (
    <textarea
      ref={controlRef}
      id={id}
      value={value}
      className={cn(styles.TextareaField, {
        [styles.TextareaFieldIsDisabled]: isDisabled
      })}
      disabled={isDisabled}
      style={{ height: `${height}px` }}
      onChange={(event) =>
        onChangeHandler(event.target.value)
      }
      onFocus={onFocusHandler}
      onBlur={onBlurHandler}
    />
  );
};
