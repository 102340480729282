// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".YWueuv6{display:block;overflow:hidden;flex-shrink:1;width:100%;max-width:100%;font-weight:normal;user-select:none;text-align:center;white-space:nowrap;text-overflow:ellipsis}.abxdUVi{color:var(--color-pink-sber)}.tCN4e4G{color:var(--color-arctic-sber)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "YWueuv6",
	"IsNegative": "abxdUVi",
	"IsPositive": "tCN4e4G"
};
export default ___CSS_LOADER_EXPORT___;
