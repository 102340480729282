import React, { type FC, useState } from 'react';
import cn from 'classnames';

import { Icon } from '../Icon';
import { Icons } from '../../../spriteImages/sprite';

import {
  Root,
  type RatingCounterProps,
  RatingCounterActiveButtonProps,
  RatingCounterClickedProps
} from '.';

import styles from './RatingCounter.module.scss';

export const RatingCounter: FC<RatingCounterProps> = ({
  count = 0,
  isDisabled = false,
  isClicked,
  increaseHandler,
  decreaseHandler,
  className,
  style,
  ...props
}) => {
  const [activeButton, setActiveButton] =
    useState<RatingCounterActiveButtonProps>(
      RatingCounterActiveButtonProps.false
    );

  const activeHandler = (
    type: RatingCounterActiveButtonProps
  ) => {
    if (activeButton !== type) {
      setActiveButton(type);
    }
  };

  return (
    <div
      className={cn(
        styles.Root,
        {
          [styles.IsDisabled]: isDisabled || isClicked
        },
        className
      )}
      style={style}
      {...props}
    >
      <Root.Button
        isActive={
          activeButton ===
            RatingCounterActiveButtonProps.minus ||
          isClicked === RatingCounterClickedProps.minus
        }
        onClick={() => {
          decreaseHandler(count);
          activeHandler(
            RatingCounterActiveButtonProps.minus
          );
        }}
      >
        <Icon name={Icons.minusRound} />
      </Root.Button>
      <Root.Value value={count} />
      <Root.Button
        isActive={
          activeButton ===
            RatingCounterActiveButtonProps.plus ||
          isClicked === RatingCounterClickedProps.plus
        }
        onClick={() => {
          increaseHandler(count);
          activeHandler(
            RatingCounterActiveButtonProps.plus
          );
        }}
      >
        <Icon name={Icons.plusRound} />
      </Root.Button>
    </div>
  );
};
