import { LinkThemes } from './types';
import Styles from './Link.module.scss';

export const LinkThemesMap = {
  [LinkThemes.default]: Styles.LinkDefault,
  [LinkThemes.black]: Styles.LinkBlack,
  [LinkThemes.success]: Styles.LinkSuccess,
  [LinkThemes.warning]: Styles.LinkWarning,
  [LinkThemes.danger]: Styles.LinkDanger
};
