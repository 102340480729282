import React, { createContext, FC } from 'react';
import cn from 'classnames';
import { canUseDOM } from './canUseDOM';
import { theme as externalTheme } from './presets/external';

export { externalTheme };

export interface AppContext {
  theme: 'external' | 'internal';
}

export type Nullable<T> = T | null;

export type Theme = {
  color: string;
  root: string;
};

type ConfigureRootThemeOptions = {
  /**
   * Корневая тема приложения.
   */
  theme: Theme;

  /**
   * DOM-Элемент на который должна быть установлена корневая тема.
   *
   * @default HTMLBodyElement
   */
  root?: Nullable<Element>;
};

let prevClassName = '';

export const configureRootTheme = ({
  theme,
  root = canUseDOM() ? document.body : null
}: ConfigureRootThemeOptions): void => {
  if (!canUseDOM) {
    return;
  }

  if (!root) {
    throw new Error(
      'Значение в root не является DOM-элементом, невозможно установить глобальную тему.'
    );
  }

  const rootClassName = root.className.replace(
    prevClassName,
    ''
  );

  prevClassName = cn(
    `Theme_color_${theme.color}`,
    `Theme_root_${theme.root}`
  );

  root.className = rootClassName
    ? `${rootClassName} ${prevClassName}`
    : prevClassName;
};

export const AppContext = createContext<AppContext>({
  theme: 'external'
});

export const AppProviderContext: FC<AppContext> = ({
  children,
  theme
}) => {
  configureRootTheme({ theme: externalTheme });

  return (
    <AppContext.Provider value={{ theme }}>
      {children}
    </AppContext.Provider>
  );
};
