// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._0g7SFU9{display:flex;justify-content:flex-start;width:100%;font-weight:normal;user-select:none;color:var(--color-graphite-sber);border-radius:0;transition:background-color .25s linear}._0g7SFU9:focus-visible{border-radius:8px;outline:1px solid rgba(151,71,255,.2);outline-offset:-4px}._0g7SFU9:hover,.dkS5oxq{background-color:var(--color-graphite-sber-4)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SelectOption": "_0g7SFU9",
	"SelectOptionIsActive": "dkS5oxq"
};
export default ___CSS_LOADER_EXPORT___;
