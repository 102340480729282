import Sprite from '../../spriteImages/stack/sprite.svg';
import { ICON_BLOCK_ID } from '../consts';

let spriteString = '';
let isRequestInProgress = false;

export const setSvgSprite = async () => {
  if (
    typeof document !== 'undefined' &&
    document.getElementById(ICON_BLOCK_ID) === null &&
    !isRequestInProgress
  ) {
    const container = document.createElement('div');

    if (!spriteString) {
      isRequestInProgress = true;

      const response = await fetch(Sprite);

      spriteString = await response.text();
    }

    container.id = ICON_BLOCK_ID;
    container.innerHTML = spriteString;
    container.style.display = 'none';

    document.body.appendChild(container);
  }
};
