// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ygydn3t{position:relative;display:flex;width:100%}.gJnMayp{flex-shrink:0;width:auto}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "ygydn3t",
	"Slide": "gJnMayp"
};
export default ___CSS_LOADER_EXPORT___;
