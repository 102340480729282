import React from 'react';
import { Skeleton } from '..';
import styles from './Skeleton-VerticalCard.module.scss';

export const SkeletonVerticalCard = () => (
  <div className={styles.Root}>
    <Skeleton
      width="152px"
      height="152px"
      borderRadius="4px"
      className={styles.Img}
    />
    <div className={styles.Wrapper}>
      <Skeleton
        width="152px"
        height="14px"
        borderRadius="4px"
      />
      <Skeleton
        width="122px"
        height="14px"
        borderRadius="4px"
      />
    </div>
    <Skeleton
      width="107px"
      height="32px"
      borderRadius="29px"
      className={styles.Btn}
    />
  </div>
);
