import React, { FC, HTMLAttributes } from 'react';
import {
  IRatingBaseProps,
  RatingNumber
} from '../../Rating';
import styles from './ProductRating.module.scss';

export interface ProductRatingProps
  extends IRatingBaseProps,
    HTMLAttributes<HTMLDivElement> {}

export const ProductRating: FC<ProductRatingProps> = ({
  ...rest
}) => {
  return <RatingNumber className={styles.Root} {...rest} />;
};
