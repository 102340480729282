import { PaginationPagesProps } from '../';

export const setAriaLabel = (
  type: PaginationPagesProps,
  page: number,
  selected: boolean
) => {
  if (type === 'page') {
    return `${selected ? `Страница ${page}` : `Перейти к странице ${page}`}`;
  }
  return `Перейти к ${type} странице`;
};
