import React, { FC } from 'react';
import cn from 'classnames';

import { Button, ButtonThemes } from '../../Button';
import { Icon, Icons } from '../../Icon';

import { InputClearButtonProps } from './types';
import { SizingProps } from '../../../types/SizingProps';

import styles from './InputClearButton.module.scss';

export const InputClearButton: FC<
  InputClearButtonProps
> = ({ isDisabled, controlRef }) => {
  const clearHandler = () => {
    if (typeof window !== 'undefined') {
      const type = window.HTMLInputElement.prototype;
      const nativeInputValueSetter =
        Object.getOwnPropertyDescriptor(type, 'value')?.set;

      if (!controlRef.current || !nativeInputValueSetter) {
        return;
      }

      nativeInputValueSetter.call(controlRef.current, '');

      const inputEvent = new Event('input', {
        bubbles: true
      });
      controlRef.current.dispatchEvent(inputEvent);
      controlRef.current.focus();
    }
  };

  return (
    <Button
      className={cn(styles.InputClearButton, {
        [styles.InputClearButtonIsDisabled]: isDisabled
      })}
      theme={ButtonThemes.ghost}
      size={SizingProps.s}
      onClick={clearHandler}
      type="button"
      aria-label="Очистить поле"
      icon
    >
      <Icon name={Icons.close} />
    </Button>
  );
};
