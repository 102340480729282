import { CSSProperties, type ReactNode } from 'react';

export enum TypographyFontProps {
  display = 'Display',
  text = 'Text'
}

export enum TypographyTagProps {
  h1 = 'h1',
  h2 = 'h2',
  h3 = 'h3',
  h4 = 'h4',
  h5 = 'h5',
  h6 = 'h6',
  p = 'p',
  div = 'div',
  span = 'span',
  i = 'i',
  strong = 'strong',
  b = 'b',
  figcaption = 'figcaption',
  sub = 'sub',
  sup = 'sup',
  time = 'time',
  small = 'small'
}

export enum TypographySizingProps {
  _72 = '72',
  _64 = '64',
  _56 = '56',
  _48 = '48',
  _40 = '40',
  _32 = '32',
  _28 = '28',
  _24 = '24',
  _22 = '22',
  _20 = '20',
  _18 = '18',
  _16 = '16',
  _14 = '14'
}

export enum TypographyFontWeightProps {
  regular = '400',
  semiBold = '600'
}

export interface TypographyProps {
  tag?: TypographyTagProps;
  fontFamily?: TypographyFontProps;
  size?: TypographySizingProps;
  sizeTablet?: TypographySizingProps;
  sizeMobile?: TypographySizingProps;
  fontWeight?: TypographyFontWeightProps;
  className?: string;
  style?: CSSProperties;
  children?: ReactNode;
}
