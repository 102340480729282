// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".J1sbj3F{display:none}@media all and (min-width: 768px){.J1sbj3F{position:absolute;z-index:5;top:50%;display:flex;justify-content:center;align-items:center;width:40px !important;height:40px;padding:0 !important;border:0;border-radius:50%;will-change:transform}.cLdXQVN{left:0;transform:translate(-50%, -50%)}.vyMdOA7{right:0;transform:translate(50%, -50%)}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "J1sbj3F",
	"RootPrev": "cLdXQVN",
	"RootNext": "vyMdOA7"
};
export default ___CSS_LOADER_EXPORT___;
