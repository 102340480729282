// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Ad2IP2X{display:flex;align-items:center;width:auto;padding:0 0 9px;font-family:var(--display-font-family);font-size:16px;font-weight:600;line-height:22px}.WK0Eo0v{margin-left:8px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "Ad2IP2X",
	"Icon": "WK0Eo0v"
};
export default ___CSS_LOADER_EXPORT___;
