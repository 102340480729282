import React, { FC } from 'react';
import cn from 'classnames';

import { InputPlaceholderProps } from './types';

import styles from './InputPlaceholder.module.scss';

export const InputPlaceholder: FC<
  InputPlaceholderProps
> = ({
  placeholder,
  isDisabled,
  isFocused,
  isError,
  labelId
}) => {
  return (
    <label
      htmlFor={labelId}
      className={cn(styles.InputPlaceholder, {
        [styles.InputPlaceholderIsDisabled]: isDisabled,
        [styles.InputPlaceholderIsFocused]: isFocused,
        [styles.InputPlaceholderIsError]: isError
      })}
    >
      {placeholder}
    </label>
  );
};
