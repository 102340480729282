import React, { type FC, useEffect } from 'react';
import { setSvgSprite } from '../../helpers/setSvgSprite';
import { type IconProps } from './types';
import { IconSizesMap } from './consts';
import { SizingProps } from '../../types/SizingProps';

import Styles from './Icon.module.scss';

export const Icon: FC<IconProps> = ({
  name,
  size = SizingProps.m,
  className = Styles.Icon,
  style,
  ...props
}) => {
  const side = IconSizesMap[size];

  useEffect(() => {
    setSvgSprite();
  }, []);

  return (
    <svg
      className={className}
      width={side}
      height={side}
      viewBox={`0 0 ${side} ${side}`}
      style={style}
      {...props}
    >
      <use
        href={`#icon-${name}`}
        x="0"
        y="0"
        width={side}
        height={side}
        {...props}
      />
    </svg>
  );
};
