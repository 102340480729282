import Styles from './Button.module.scss';
import { SizingProps } from '../../types/SizingProps';
import { ButtonThemes } from './types';

export const buttonSizesMap = {
  [SizingProps.s]: Styles.ButtonSizeS,
  [SizingProps.m]: Styles.ButtonSizeM,
  [SizingProps.l]: Styles.ButtonSizeL,
  [SizingProps.xl]: Styles.ButtonSizeXL
};

export const ButtonThemesMap = {
  [ButtonThemes.primary]: Styles.ButtonPrimary,
  [ButtonThemes.secondary]: Styles.ButtonSecondary,
  [ButtonThemes.tertiary]: Styles.ButtonTertiary,
  [ButtonThemes.ghost]: Styles.ButtonGhost
};
