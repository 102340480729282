import React, { type FC } from 'react';
import cn from 'classnames';

import { TextareaGroupProps } from '.';

import styles from './TextareaGroup.module.scss';

export const TextareaGroup: FC<TextareaGroupProps> = ({
  children,
  isError,
  isDisabled,
  isFocused
}) => {
  return (
    <div
      className={cn(styles.TextareaGroup, {
        [styles.TextareaGroupIsError]: isError,
        [styles.TextareaGroupIsDisabled]: isDisabled,
        [styles.TextareaGroupIsFocused]: isFocused
      })}
    >
      {children}
    </div>
  );
};
