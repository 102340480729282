import React, { FC, HTMLAttributes } from 'react';
import { Icons } from '../../Icon';
import { ProductInformer } from './ProductInformer';

export interface ProductInformerSeoProps
  extends HTMLAttributes<HTMLDivElement> {
  link: string;
}

export const ProductInformerSeo: FC<
  ProductInformerSeoProps
> = ({ link, ...rest }) => {
  return (
    <ProductInformer
      iconName={Icons.drug}
      iconColor="#8168F0"
      link={link}
      {...rest}
    >
      Все формы выпуска
    </ProductInformer>
  );
};
