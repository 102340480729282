// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".\\+raJoBY{display:flex;justify-content:center;align-items:center;padding:0;font-family:var(--control-font-family);font-size:14px;cursor:pointer;user-select:none;color:var(--color-graphite-sber-1);border:0;border-radius:8px;outline:0;background-color:var(--color-white);transition:all .2s linear;appearance:none}.\\+raJoBY:hover{color:var(--color-violet)}.QqQMKLp{pointer-events:none;color:var(--color-graphite-sber-3);touch-action:none}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PaginationArrow": "+raJoBY",
	"PaginationArrowIsDisabled": "QqQMKLp"
};
export default ___CSS_LOADER_EXPORT___;
