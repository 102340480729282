// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fL6qXOl{position:relative;overflow:hidden;border-radius:8px}.q8P9utr{position:absolute;z-index:3;top:0;left:0;width:100%;height:100%;cursor:pointer;border-radius:8px;outline:none;box-shadow:none;transition:box-shadow .2s ease}.q8P9utr:hover{box-shadow:var(--shadow-light-box)}.q8P9utr:focus-visible{outline:2px solid var(--color-violet-1);outline-offset:-2px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "fL6qXOl",
	"Link": "q8P9utr"
};
export default ___CSS_LOADER_EXPORT___;
