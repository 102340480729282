/* eslint-disable no-unused-vars */
// incorrect work of lint rule in this file

/**
 * Словарь ключей брейкпоинтов
 */
export enum BreakPointsKeys {
  xxs = 'xxs',
  xs = 'xs',
  sm = 'sm',
  md = 'md',
  lg = 'lg',
  xl = 'xl'
}

/**
 * Модель карты брейкпоинтов
 */
export type breakPointsMap = {
  [key in BreakPointsKeys]: number;
};

/**
 * Модель данный получаемая из контроллер `ViewSizeController`
 *
 * @param currentBreakPoint - Текущий брейкпоинт из списка `BreakPointsKeys`
 * @param isDesktop - `true`, если брейкпоинт нахождения в диапозоне `desktopViewBreakPoints`, иначе `false`
 * @param isMobile - `true`, если брейкпоинт нахождения в диапозоне `mobileViewBreakPoints`, иначе `false`
 */
export interface ViewSizeControllerData {
  currentBreakPoint: BreakPointsKeys;
  isDesktop: boolean;
  isMobile: boolean;
}

// нужно добавить его экспорт из @npm-registry/helpers/dispatcher
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type DispatcherSubscriber<A extends any[]> = (
  ...args: A
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
) => any;

/**
 * Атрибуты функции подписки на ViewSizeController
 */
export type SubscriberAttributes = [ViewSizeControllerData];

/**
 * функция подписки на ViewSizeController
 */
export type ViewSizeControllerSubscriber =
  DispatcherSubscriber<SubscriberAttributes>;
