// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".po1nbxz{font-size:16px;line-height:22px;color:var(--color-orange)}@media(max-width: 900px){.po1nbxz{font-size:14px;line-height:18px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "po1nbxz"
};
export default ___CSS_LOADER_EXPORT___;
