import React, { FC, HTMLAttributes } from 'react';
import { Icons } from '../../Icon';
import { ProductInformer } from './ProductInformer';

export const ProductInformerInstallment: FC<
  HTMLAttributes<HTMLDivElement>
> = (rest) => {
  return (
    <ProductInformer iconName={Icons.bnpl} {...rest}>
      Плати частями
    </ProductInformer>
  );
};
