// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".dpIx3yl{display:block;overflow:hidden;flex-shrink:1;width:100%;padding:16px 16px 0;white-space:nowrap;text-overflow:ellipsis;color:rgba(0,0,0,0);border:0;border-radius:8px;outline:0;background:rgba(0,0,0,0);transition:all .25s ease;appearance:none}.dpIx3yl:-webkit-autofill,.dpIx3yl:-webkit-autofill:hover,.dpIx3yl:-webkit-autofill:focus{-webkit-text-fill-color:var(--color-graphite-sber-2);-webkit-box-shadow:0 0 0 1000px #fff inset;transition:background-color 5000s ease-in-out 0s}.dpIx3yl:-webkit-autofill:disabled{-webkit-box-shadow:0 0 0 1000px var(--color-graphite-sber-3) inset;transition:background-color 5000s ease-in-out 0s}.pJk2iUq{color:var(--color-graphite-sber-2)}.em9qcqO{pointer-events:none;touch-action:none}._9-XMfFC{height:46px;font-size:14px;line-height:18px}._0pBrJbE{height:54px;font-size:16px;line-height:20px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"InputField": "dpIx3yl",
	"InputFieldIsFocused": "pJk2iUq",
	"InputFieldIsReadonly": "em9qcqO",
	"InputFieldSizeM": "_9-XMfFC",
	"InputFieldSizeL": "_0pBrJbE"
};
export default ___CSS_LOADER_EXPORT___;
