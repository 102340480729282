import React, { FC } from 'react';
import cn from 'classnames';

import { SwitcherVariantProps } from '../Switcher';

import styles from './Switcher-Slider.module.scss';

export interface ISwitchSliderProps {
  /**
   * Состояние Checked/Unchecked переключателя
   */
  state: boolean | undefined;
  variant?: SwitcherVariantProps;
}

export const SwitchSlider: FC<ISwitchSliderProps> = ({
  state,
  variant = SwitcherVariantProps.default
}) => (
  <span
    className={cn(styles.Slider)}
    role="checkbox"
    aria-checked={state}
    data-variant={variant}
  />
);
