/* eslint-disable @typescript-eslint/ban-ts-comment */
import { Dispatcher } from './helpers/Dispatcher';
import { getCurrentBreakPoint } from './helpers/getCurrentBreakPoint';
import { checkIsDesktopBreakPoint } from './helpers/checkIsDesktopBreakPoint';
import { checkIsMobileBreakPoint } from './helpers/checkIsMobileBreakPoint';
import {
  BreakPointsKeys,
  SubscriberAttributes,
  ViewSizeControllerData,
  ViewSizeControllerSubscriber
} from './types';
import { viewBreakPoints } from './const';

const ViewSizeControllerSymbol = Symbol(
  'ViewSizeControllerSymbol'
);

/**
 * Контроллер размерности окна браузера,
 * реализует отслеживание resize на объекте window,
 * записывает текщее состояние и передаёт его в подписчиков
 */
export class ViewSizeController {
  private dispatcherInstance =
    new Dispatcher<SubscriberAttributes>();
  /**
   * Текущий брейкпоинт из списка `BreakPointsKeys`
   */
  private _currentBreakPoint: BreakPointsKeys =
    getCurrentBreakPoint(
      typeof window !== 'undefined'
        ? window.innerWidth
        : viewBreakPoints[BreakPointsKeys.xl]
    );
  /**
   * `true`, если брейкпоинт нахождения в диапозоне `desktopViewBreakPoints`, иначе `false`
   */
  private _isDesktop: boolean = checkIsDesktopBreakPoint(
    this._currentBreakPoint
  );
  /**
   * `true`, если брейкпоинт нахождения в диапозоне `mobileViewBreakPoints`, иначе `false`
   */
  private _isMobile: boolean = checkIsMobileBreakPoint(
    this._currentBreakPoint
  );

  private constructor(
    enforcer: typeof ViewSizeControllerSymbol
  ) {
    if (enforcer !== ViewSizeControllerSymbol) {
      throw new Error('Cannot construct singleton');
    }
  }

  /**
   * Экземпляр контроллера размерности окна браузера,
   * реализует отслеживание resize на объекте window,
   * записывает текщее состояние и передаёт его в подписчиков
   */
  static get instance() {
    // @ts-ignore
    if (!this[ViewSizeControllerSymbol]) {
      // @ts-ignore
      this[ViewSizeControllerSymbol] =
        new ViewSizeController(ViewSizeControllerSymbol);

      if (typeof window !== 'undefined') {
        window.addEventListener(
          'resize',
          // @ts-ignore
          this[ViewSizeControllerSymbol].handleWindowResize
        );
      }
    }

    // @ts-ignore
    return this[
      ViewSizeControllerSymbol
    ] as ViewSizeController;
  }

  /**
   * @returns - Текущий брейкпоинт из списка `BreakPointsKeys`
   */
  get currentBreakPoint() {
    return this._currentBreakPoint;
  }

  /**
   * @returns - `true`, если брейкпоинт нахождения в диапозоне `desktopViewBreakPoints`, иначе `false`
   */
  get isDesktop() {
    return this._isDesktop;
  }

  /**
   * @returns -  `true`, если брейкпоинт нахождения в диапозоне `mobileViewBreakPoints`, иначе `false`
   */
  get isMobile() {
    return this._isMobile;
  }

  /**
   * @returns - Возвращает текущее состояние контроллера
   */
  get viewSizeData(): ViewSizeControllerData {
    return {
      currentBreakPoint: this.currentBreakPoint,
      isDesktop: this.isDesktop,
      isMobile: this.isMobile
    };
  }

  /**
   * Метод отправлеят изменения в подписчиков
   */
  private updateSubscribers = () => {
    this.dispatcherInstance.dispatch(this.viewSizeData);
  };

  /**
   * Метод обрабатывает события resize
   */
  // @ts-ignore
  private handleWindowResize = (event: Event<Window>) => {
    const newBreakPoint = getCurrentBreakPoint(
      event.target.innerWidth
    );

    if (newBreakPoint !== this.currentBreakPoint) {
      this._currentBreakPoint = newBreakPoint;

      if (this.currentBreakPoint) {
        this._isDesktop = checkIsDesktopBreakPoint(
          this.currentBreakPoint
        );
        this._isMobile = checkIsMobileBreakPoint(
          this.currentBreakPoint
        );
      }

      this.updateSubscribers();
    }
  };

  subscribe = (
    subscriber: ViewSizeControllerSubscriber
  ) => {
    this.dispatcherInstance.subscribe(subscriber);
  };

  unsubscribe = (
    subscriber: ViewSizeControllerSubscriber
  ) => {
    this.dispatcherInstance.unsubscribe(subscriber);
  };
}
