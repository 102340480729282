// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, "._2d9aE4s{display:grid;grid-template-areas:\"img text\" \"img btn\";grid-template-columns:148px 1fr;grid-template-rows:auto auto;grid-gap:0 12px}.OyluNb6{grid-area:img}.S-fk2fO{display:flex;flex-flow:column nowrap;grid-area:text;gap:12px}.fewQNIz{align-self:end;grid-area:btn}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "_2d9aE4s",
	"Img": "OyluNb6",
	"Wrapper": "S-fk2fO",
	"Btn": "fewQNIz"
};
export default ___CSS_LOADER_EXPORT___;
