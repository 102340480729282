import React, { type FC, LinkHTMLAttributes } from 'react';
import clsx from 'clsx';
import { LinkThemes, type LinkProps } from './types';
import { LinkThemesMap } from './consts';
import Styles from './Link.module.scss';

export const Link = <
  Element = HTMLLinkElement,
  ReactAttributes = LinkHTMLAttributes<Element>
>({
  className,
  children,
  theme = LinkThemes.default,
  ...props
}: LinkProps<Element, ReactAttributes>) => {
  const Root = 'a' as unknown as FC;

  return (
    <Root
      className={clsx(
        Styles.Link,
        className,
        LinkThemesMap[theme]
      )}
      {...props}
    >
      {children}
    </Root>
  );
};
