import { ChangeEvent } from 'react';

import {
  PaginationProps,
  PaginationPagesProps
} from '../../components/Pagination';

export const usePagination = ({
  boundaryCount,
  count,
  disabled,
  onChange: handleChange,
  page,
  siblingCount
}: PaginationProps) => {
  const handleClick = (
    event: ChangeEvent<unknown>,
    value: number
  ) => {
    if (handleChange) {
      handleChange(event, value);
    }
  };

  // https://dev.to/namirsab/comment/2050
  const range = (start: number, end: number) => {
    const length = end - start + 1;
    return Array.from({ length }, (_, i) => start + i);
  };

  const startPages = range(
    1,
    Math.min(boundaryCount, count)
  );
  const endPages = range(
    Math.max(count - boundaryCount + 1, boundaryCount + 1),
    count
  );

  const siblingsStart = Math.max(
    Math.min(
      // Natural start
      Number(page) - siblingCount,
      // Lower boundary when page is high
      count - boundaryCount - siblingCount * 2 - 1
    ),
    // Greater than startPages
    boundaryCount + 2
  );

  const siblingsEnd = Math.min(
    Math.max(
      // Natural end
      Number(page) + siblingCount,
      // Upper boundary when page is low
      boundaryCount + siblingCount * 2 + 2
    ),
    // Less than endPages
    count - boundaryCount - 1
  );

  // Basic list of items to render
  // for example itemList = ['first', 'previous', 1, 'ellipsis', 4, 5, 6, 'ellipsis', 10, 'next', 'last']
  const itemList = [
    ...startPages,

    // Start ellipsis
    ...(siblingsStart > boundaryCount + 2
      ? ['start-ellipsis']
      : boundaryCount + 1 < count - boundaryCount
        ? [boundaryCount + 1]
        : []),

    // Sibling pages
    ...range(siblingsStart, siblingsEnd),

    // End ellipsis
    ...(siblingsEnd < count - boundaryCount - 1
      ? ['end-ellipsis']
      : count - boundaryCount > boundaryCount
        ? [count - boundaryCount]
        : []),

    ...endPages
  ];

  // Map the button type to its page number
  const buttonPage = (
    type: number | PaginationPagesProps
  ) => {
    switch (type) {
      case PaginationPagesProps.first:
        return 1;
      case PaginationPagesProps.previous:
        return Number(page) - 1;
      case PaginationPagesProps.next:
        return Number(page) + 1;
      case PaginationPagesProps.last:
        return count;
      default:
        return null;
    }
  };

  // Convert the basic item list to PaginationItem props objects
  const items = itemList.map((item) => {
    return typeof item === 'number'
      ? {
          onClick: (event: ChangeEvent<unknown>) => {
            handleClick(event, item);
          },
          type: PaginationPagesProps.page,
          page: item,
          selected: item === page,
          disabled,
          'aria-current':
            item === page
              ? PaginationPagesProps.page
              : undefined
        }
      : {
          onClick: (event: ChangeEvent<unknown>) => {
            handleClick(
              event,
              buttonPage(item as PaginationPagesProps)
            );
          },
          type: item,
          page: buttonPage(item as PaginationPagesProps),
          selected: false,
          disabled:
            disabled ||
            (!item.includes('ellipsis') &&
              (item === PaginationPagesProps.next ||
              item === PaginationPagesProps.last
                ? page >= count
                : page <= 1))
        };
  });

  return {
    items
  };
};
