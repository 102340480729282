// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".sQwLh08{margin:0;padding:0;font-family:var(--control-font-family);font-size:1rem;cursor:pointer;text-decoration:none;border:0;outline:0;background:rgba(0,0,0,0);transition:color .15s linear;touch-action:manipulation;-webkit-tap-highlight-color:rgba(0,0,0,0)}.sQwLh08:disabled,.sQwLh08[aria-disabled=true]{pointer-events:none;color:var(--color-violet-3)}.ZGapanC{color:var(--color-violet)}.ZGapanC:hover{color:var(--color-violet-2)}.MPSJXZW{color:var(--color-black)}.MPSJXZW:hover{color:var(--color-violet)}.QM1lf83{color:var(--color-arctic-sber)}.QM1lf83:hover{color:var(--color-sberspasibo)}.ImAtqC2{color:var(--color-orange-2)}.ImAtqC2:hover{color:var(--color-orange-1)}.dnuTSxR{color:var(--color-pink-sber)}.dnuTSxR:hover{color:var(--color-pink-sber)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Link": "sQwLh08",
	"LinkDefault": "ZGapanC",
	"LinkBlack": "MPSJXZW",
	"LinkSuccess": "QM1lf83",
	"LinkWarning": "ImAtqC2",
	"LinkDanger": "dnuTSxR"
};
export default ___CSS_LOADER_EXPORT___;
