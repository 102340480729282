import React, { type FC, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import cn from 'classnames';

import { useInputText } from '../../hooks/useInputText';
import { useTextareaDynamicHeight } from '../../hooks/useTextareaDynamicHeight';

import { Area, type TextareaProps } from '.';

import styles from './Textarea.module.scss';

export const Textarea: FC<TextareaProps> = ({
  id,
  value = '',
  className,
  placeholder,
  isDisabled = false,
  isError = false,
  errorText = '',
  description,
  style,
  onChangeHandler,
  onFocusHandler,
  onBlurHandler,
  ...props
}) => {
  const uuID = useRef(uuid());
  const textAreaRef = useRef(null);
  const ID = id ? id : uuID.current;

  const {
    hasFocus: isFocused,
    onFocus,
    onBlur
  } = useInputText({
    onFocus: onFocusHandler,
    onBlur: onBlurHandler
  });

  useTextareaDynamicHeight({ textAreaRef, value });

  return (
    <div
      className={cn(styles.Root, className)}
      style={style}
      {...props}
    >
      <Area.Group
        isError={isError}
        isDisabled={isDisabled}
        isFocused={isFocused || value !== ''}
      >
        {placeholder && (!isError || errorText === '') && (
          <Area.Placeholder
            placeholder={placeholder}
            isDisabled={isDisabled}
            isFocused={isFocused || value !== ''}
            isError={isError}
            labelId={ID}
          />
        )}
        <Area.Field
          controlRef={textAreaRef}
          id={ID}
          value={value}
          isDisabled={isDisabled}
          onChangeHandler={onChangeHandler}
          onFocusHandler={onFocus}
          onBlurHandler={onBlur}
        />
        {isError && errorText !== '' && (
          <Area.Error
            errorText={errorText}
            isError={isError}
            isDisabled={isDisabled}
            isFocused={isFocused || value !== ''}
          />
        )}
      </Area.Group>
      {description && (
        <Area.Description description={description} />
      )}
    </div>
  );
};
