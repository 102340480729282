// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WG5w0Gh{height:14px;border-radius:4px;background-color:var(--color-graphite-sber-4)}.WG5w0Gh.kDd01GF{background:linear-gradient(to bottom right, #dee1eb 8%, #f6f7fa 38%, #dee1eb 54%);background-size:1000px 640px;animation:H1-H8X9 2s;animation-timing-function:ease-out;animation-iteration-count:infinite;animation-fill-mode:forwards}@keyframes H1-H8X9{0%{background-position:0 0}100%{background-position:100% 50%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "WG5w0Gh",
	"Animated": "kDd01GF",
	"Skeleton": "H1-H8X9"
};
export default ___CSS_LOADER_EXPORT___;
