import cn from 'classnames';
import React, { FC, HTMLAttributes } from 'react';

import {
  Typography,
  TypographyProps,
  TypographyTagProps,
  TypographyFontWeightProps
} from '../../Typography';

import styles from './ProductTitle.module.scss';

export interface ProductTitleProps
  extends TypographyProps,
    HTMLAttributes<HTMLHeadingElement> {}

export const ProductTitle: FC<ProductTitleProps> = ({
  className,
  tag = TypographyTagProps.h6,
  fontWeight = TypographyFontWeightProps.semiBold,
  ...rest
}) => {
  return (
    <Typography
      tag={tag}
      fontWeight={fontWeight}
      className={cn(styles.Root, className)}
      {...rest}
    />
  );
};
