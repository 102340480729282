// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JXwS5zu{--border-width: 1px;position:relative;display:inline-block;width:auto;height:auto;padding:0 10px;font-size:16px;cursor:pointer;white-space:nowrap;border:solid var(--border-width) rgba(0,0,0,0);border-radius:20px;background:#fff;background-clip:padding-box}.JXwS5zu::before{position:absolute;z-index:-1;inset:0;margin:calc(var(--border-width)*-1);content:\"\";border-radius:inherit;background-image:var(--gradient-bonus-eapteka)}.uYOZC2y{line-height:18px;color:rgba(0,0,0,0);background-image:var(--gradient-bonus-eapteka);-webkit-background-clip:text;background-clip:text}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Root": "JXwS5zu",
	"Text": "uYOZC2y"
};
export default ___CSS_LOADER_EXPORT___;
