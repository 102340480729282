import React, { FC, HTMLAttributes } from 'react';
import {
  Typography,
  TypographySizingProps
} from '../../Typography';
import { formatBonus } from '../helpers/formatBonus';
import styles from './ProductBonus.module.scss';

export interface ProductBonusProps
  extends HTMLAttributes<HTMLButtonElement> {
  value: number;
}

export const ProductBonus: FC<ProductBonusProps> = ({
  value,
  ...rest
}) => {
  return (
    <button className={styles.Root} {...rest}>
      <Typography
        size={TypographySizingProps._14}
        className={styles.Text}
      >
        {formatBonus(value)}
      </Typography>
    </button>
  );
};
