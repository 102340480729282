import type { CSSProperties, Ref } from 'react';

export enum SkeletonTypes {
  horizontalCard = 'horizontalCard',
  verticalCard = 'verticalCard'
}

export interface ISkeletonProps {
  /**
   * CSS-стили иконки
   *
   * @default {}
   */
  style?: CSSProperties;

  /**
   * Дополнительный класс
   */
  className?: string;

  /**
   * Ширина скелетона
   */
  width?: string | number;

  /**
   * Высота скелетона
   */
  height?: string | number;

  /**
   * Скругление границ/форма скелетона
   */
  borderRadius?: number | string;

  /**
   * Тип блоков со скелетонами
   */
  type?: SkeletonTypes;

  /**
   * Анимирование скелетона
   * @default true
   */
  animated?: boolean;

  /**
   * Ссылка на DOM-элемент нативного контрола
   */
  controlRef?: Ref<HTMLDivElement>;

  /**
   * Ссылка на элемент
   */
  innerRef?: Ref<HTMLDivElement>;
}
