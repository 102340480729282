// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".mLsAI40{display:flex;align-items:center;box-sizing:border-box;width:44px;height:24px;padding:2px;cursor:pointer;border-radius:20px;background-color:var(--color-graphite-sber-2);transition:background-color .3s ease}.mLsAI40::after{display:block;width:20px;height:20px;margin-left:0;content:\"\";border-radius:20px;background-color:var(--color-white);transition:margin-left .2s linear;transition-delay:.2s;animation:uzGrTfZ .5s ease-in forwards}.mLsAI40[aria-checked=true][data-variant=default]{background-color:var(--color-violet)}.mLsAI40[aria-checked=true][data-variant=green]{background-color:var(--color-sberspasibo)}.mLsAI40[aria-checked=true]::after{margin-left:50%;animation:_8X7tar3 .5s ease-in forwards}@keyframes _8X7tar3{0%{padding:0}33%{padding:0 12px 0 0}66%{padding:0 0 0 12px}100%{padding:0}}@keyframes uzGrTfZ{0%{padding:0}33%{padding:0 0 0 12px}66%{padding:0 12px 0 0}100%{padding:0}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"Slider": "mLsAI40",
	"switchOff": "uzGrTfZ",
	"switchOn": "_8X7tar3"
};
export default ___CSS_LOADER_EXPORT___;
