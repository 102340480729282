// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".QXHcNQc{display:block;overflow:auto;width:100%;min-height:84px;max-height:110px;padding:34px 16px 8px;font-family:var(--control-font-family);font-size:14px;line-height:18px;resize:none;color:var(--color-graphite-sber);border:0;border-radius:8px;background:rgba(0,0,0,0);appearance:none}.QXHcNQc:focus{outline:0}.QXHcNQc::-webkit-scrollbar{width:8px;background:var(--color-white)}.QXHcNQc::-webkit-scrollbar-track{width:8px;background-color:var(--color-white)}.QXHcNQc::-webkit-scrollbar-thumb{width:8px;border:2px solid var(--color-white);border-radius:8px;background:var(--color-graphite-sber-3)}.k25V3vq::-webkit-scrollbar,.k25V3vq::-webkit-scrollbar-track{background:var(--color-graphite-sber-3)}.k25V3vq::-webkit-scrollbar-thumb{border-color:var(--color-graphite-sber-3)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"TextareaField": "QXHcNQc",
	"TextareaFieldIsDisabled": "k25V3vq"
};
export default ___CSS_LOADER_EXPORT___;
