import { Ref, useMemo } from 'react';
import { setRef } from './setRef';

/**
 * обновляет две ссылки, например внешнюю и собственную
 * [Подробности:](https://github.com/facebook/react/issues/13029#issuecomment-497629971)
 *
 * Необходим в ситуациях, когда нужно иметь доступ к нативному dom узлу внутри компонента,
 * и при этом предоставить разработчику извне также обратиться к этому узлу
 */

export function useForkRef<T>(
  refA: Ref<T> | null | undefined,
  refB: Ref<T> | null | undefined
): Ref<T> | null {
  return useMemo(() => {
    if (refA == null && refB == null) {
      return null;
    }
    return (refValue: T | null) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
}
